<template>
  <section :id="blok.id" v-editable="blok">
    <div
      v-if="blok.highLightedCaseStudy && highLightedCaseStudy"
      class="bg-peach-100 py-6 sm:py-12 px-4"
    >
      <div class="mx-auto max-w-6xl grid md:grid-cols-2 md:gap-6">
        <nuxt-link
          class="cursor-pointer"
          :to="$localizedPath(highLightedCaseStudy.full_slug)"
        >
          <div
            v-if="highLightedCaseStudy.content.image.filename"
            class="h-80 w-full bg-gray-100 bg-no-repeat bg-cover rounded-lg mb-4"
            :style="{
              'background-image':
                'url(' +
                $transformStoryblokImage(
                  highLightedCaseStudy.content.image.filename,
                  '/m/730x0',
                ) +
                ')',
            }"
          ></div>
        </nuxt-link>
        <div>
          <nuxt-link
            class="cursor-pointer"
            :to="$localizedPath(highLightedCaseStudy.full_slug)"
          >
            <h2>
              <RichText
                v-if="highLightedCaseStudy.content.title"
                :blok="highLightedCaseStudy.content.title"
                class="text-2xl sm:text-3xl font-semibold my-4"
                plain
              />
            </h2>
          </nuxt-link>
          <div
            v-if="highLightedCaseStudy?.content?.caseStudyCardTags?.length > 0"
            class="flex flex-wrap gap-1"
          >
            <div
              v-for="tag in highLightedCaseStudy.content.caseStudyCardTags"
              :key="tag._uid"
              class="bg-peach-500 p-1.5 rounded-lg text-xs"
            >
              <RichText
                v-if="tag.richTextContent"
                :blok="tag.richTextContent"
                class="case-study__tag"
              />
            </div>
          </div>
          <div class="text-gray-600">
            <RichText
              v-if="highLightedCaseStudy.content.intro"
              :blok="highLightedCaseStudy.content.intro"
            />
          </div>
          <r-button
            icon="arrow-right-long"
            icon-class="ml-2"
            icon-position-after
            :label="$t('read_more')"
            size="small"
            :to="$localizedPath(highLightedCaseStudy.full_slug)"
            width="wide"
          />
        </div>
      </div>
    </div>
    <div class="mx-auto max-w-6xl px-4 py-12">
      <RichText
        v-if="blok.caseStudiesTitle"
        :blok="blok.caseStudiesTitle"
        class="text-2xl text-center"
      />
      <div
        class="mt-8 mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-10 items-stretch"
      >
        <div
          v-for="caseStudy in caseStudies"
          :key="caseStudy._uid"
          class="flex items-stretch"
          :class="{ hidden: caseStudy.content.articleTranslationHidden }"
        >
          <r-article-card
            :author-name="caseStudy.content.authorName"
            class="h-full"
            :date="caseStudy.first_published_at"
            :image="caseStudy.content.image.filename"
            :intro="caseStudy.content.intro"
            :slug="caseStudy.full_slug"
            :tags="caseStudy.content.caseStudyCardTags"
            :title="caseStudy.content.title"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RArticleCard from '~/components/r-interface-components/r-article-card';

export default {
  name: 'CaseStudies',

  components: {
    RArticleCard,
  },

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  async setup(props) {
    const storyblokApi = useStoryblokApi();
    const nuxtApp = useNuxtApp();

    const caseStudies = ref([]);
    const highLightedCaseStudy = ref(null);

    const filterArray = (data) => {
      const studies = [];

      data.forEach((caseStudy) => {
        if (caseStudy.uuid === props.blok.highLightedCaseStudy) {
          highLightedCaseStudy.value = caseStudy;
        } else {
          studies.push(caseStudy);
        }
      });

      caseStudies.value = studies;
    };

    const caseStudiesListForFetch = () => {
      const list = [];

      if (props.blok?.selectedCaseStudies?.length > 0) {
        list.push(...props.blok.selectedCaseStudies);

        if (props.blok.highLightedCaseStudy) {
          list.push(props.blok.highLightedCaseStudy);
        }
      }

      return list.join(',');
    };

    const { data: articles, error } = await useAsyncData(async () => {
      const options = {
        version: nuxtApp.$config.public.STORYBLOK_VERSION,
        language: nuxtApp.$getLocale(),
        starts_with: `${nuxtApp.$getCountry() === 'pl' ? 'poland' : 'estonia'}/case-studies/`,
        is_startpage: 0,
        page: 1,
        content_type: 'CaseStudy',
        by_uuids: caseStudiesListForFetch(),
      };

      const response = await storyblokApi.get(`cdn/stories`, options);

      return response.data.stories;
    });

    filterArray(articles.value);

    if (error.value) {
      console.error(error.value);
    }

    return {
      caseStudies,
    };
  },

  data() {
    return {
      highLightedCaseStudy: null,
    };
  },
};
</script>

<style lang="scss">
.case-study__tag.r-rich-text {
  p {
    @apply mt-0 mb-0;
  }
}
</style>
