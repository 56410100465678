<template>
  <div>
    <Form
      ref="utilitiesObserver"
      v-slot="{ meta, validate }"
      class="bg-gray-50 flex flex-col gap-2 pt-2"
    >
      <div class="bg-white px-6 py-4 flex flex-col gap-2">
        <span class="font-medium">{{ $t('services.label.choose_service') }}</span>
        <Field
          v-if="isAddServiceView"
          v-slot="{ meta }"
          rules="isNotEmptyOption"
          vid="utilityOptions"
          name="utilityOptions"
        >
          <r-select
            ref="utilityOptions"
            v-model="selectedService"
            name="utilityOptions"
            data-testid="select.utility-item-view.service-and-utility"
            :disabled="isEditServiceView"
            :error-text="$t('form.validation.is_required')"
            :label="$t('services.label.service')"
            label-class="text-xs"
            :options="listOfServices"
            :placeholder="$t('services.label.choose_service')"
            required
            :validation-failed="meta?.validated && !meta.valid"
            :validation-passed="meta?.validated && meta.valid"
          />
        </Field>
        <Field
          v-if="isEditServiceView"
          v-slot="{ meta }"
          v-model="selectedService.label"
          rules="required"
          vid="utilityOptionsDisabled"
          name="utilityOptionsDisabled"
        >
          <r-input
            ref="utilityOptionsDisabled"
            v-model="selectedService.label"
            disabled
            :label="$t('services.label.service_name')"
            label-class="text-xs"
            name="utilityOptionsDisabled"
            :validation-failed="meta?.validated && !meta.valid"
            :validation-passed="meta?.validated && meta.valid"
          />
        </Field>
        <Field
          v-if="isCustomService"
          v-slot="{ meta, errors }"
          v-model="customServiceName"
          rules="required|max:24"
          vid="customServiceName"
          name="customServiceName"
        >
          <r-input
            ref="customServiceName"
            v-model="customServiceName"
            maxlength="24"
            :error-text="errors[0]"
            :label="$t('services.label.service_name')"
            label-class="text-xs"
            name="customServiceName"
            :validation-failed="meta?.validated && !meta.valid"
            :validation-passed="meta?.validated && meta.valid"
          />
        </Field>
      </div>
      <div
        class="px-6 bg-white py-4 flex flex-col gap-2"
        :class="{ invisible: !editableService.type }"
      >
        <span class="font-medium">{{ $t('services.label.who_pays') }}</span>
        <Field
          v-slot="{ meta }"
          v-model="editableService.whoIsPaying"
          rules="required"
          vid="utilityPayer"
          name="utilityPayer"
        >
          <r-tabs
            ref="utilityPayer"
            v-model="editableService.whoIsPaying"
            data-testid="component.utility-item-view.payer"
            :error-text="$t('form.validation.is_required')"
            :tabs="payerRoleTabs"
            tabs-border-class="border-gray-50"
            :validation-failed="meta?.validated && !meta.valid"
            wrapped
          />
        </Field>
      </div>
      <div v-if="editableService.type" class="px-6 bg-white py-4 flex flex-col gap-2">
        <div class="flex flex-col gap-1">
          <span class="font-medium">{{ $t('form.label.payment_determination') }}</span>
          <p class="opacity-60 text-sm">
            {{ $t('services.label.payment_determination_subtitle') }}
          </p>
        </div>
        <Field
          v-slot="{ meta }"
          v-model="editableService.paymentMethod"
          rules="required"
          vid="utilityPaymentDetermination"
          name="utilityPaymentDetermination"
        >
          <r-tabs
            ref="utilityPaymentDetermination"
            v-model="editableService.paymentMethod"
            data-testid="component.utility-item-view.paymentMethod"
            :error-text="$t('form.validation.is_required')"
            :tabs="paymentTypeTabs"
            tabs-border-class="border-gray-50"
            :validation-failed="meta?.validated && !meta.valid"
            wrapped
          />
        </Field>
        <Field
          v-if="isFixedCost"
          v-slot="{ meta }"
          v-model="paymentAmountModel"
          :rules="{ required: true, isFloat: true }"
          vid="utilityPaymentAmount"
          name="utilityPaymentAmount"
        >
          <r-input
            ref="utilityPaymentAmount"
            v-model="paymentAmountModel"
            data-testid="input.utility-item-view.payment-amount"
            :error-text="$t('form.validation.is_float')"
            field-type="tel"
            inputmode="decimal"
            :label="$t('form.label.cost') + ' ' + '(' + currency + ')'"
            label-class="text-xs"
            name="utilityPaymentAmount"
            required
            :validation-failed="
              (meta?.validated && !meta.valid) || (meta?.touched && !meta.valid)
            "
            :validation-passed="meta?.validated && meta.valid"
            @blur="meta.touched = true"
          />
        </Field>
      </div>
      <div v-if="isFixedCost" class="px-6 bg-white py-4 flex flex-col gap-2">
        <div class="flex flex-col gap-1">
          <span class="font-medium">{{ $t('agreement.label.payment_details') }}</span>
          <p class="opacity-60 text-sm">
            {{ $t('services.label.payment_details_subtitle') }}
          </p>
        </div>
        <r-textarea
          v-model="editableService.payment.details"
          data-testid="textarea.utility-item-view.payment-details"
          :label="`${$t('agreement.label.payment_details')} (${$t('form.label.optional').toLowerCase()})`"
          label-class="text-xs pb-1"
          maxlength="500"
          name="paymentDetails"
          :placeholder="$t('form.placeholder.payment_details')"
          rows="4"
        />
      </div>
      <r-sticky-container class="mt-12" sticky>
        <div class="grid grid-cols-2 justify-center gap-6 bg-white w-full sm:w-max">
          <r-button
            v-if="isEditServiceView"
            class="w-full sm:w-max"
            data-testid="button.service-and-utility-item-view.delete"
            icon="trash"
            icon-class="pr-2"
            inverted
            :label="$t('buttons.delete')"
            @click="emitDeleteServiceClick"
          />
          <r-button
            v-if="isAddServiceView"
            class="w-full sm:w-max"
            inverted
            :label="$t('buttons.back')"
            width="wide"
            @click="emitBackButtonClick"
          />
          <r-button
            class="w-full sm:w-max"
            data-testid="button.service-and-utility-item-view.save"
            :label="$t('buttons.save')"
            width="wide"
            @click="
              meta?.valid
                ? emitSaveServiceClick()
                : validateAndScrollToFailedValidation(validate)
            "
          />
        </div>
      </r-sticky-container>
    </Form>
  </div>
</template>

<script>
import { differenceBy, startsWith } from 'lodash';
import {
  defaultPolandServicesAndUtilities,
  fullListOfServicesAndUtilities,
  payerRoleTabsOptions,
  paymentTypeTabsOptions,
} from '~/utils/objectStructures';
import {
  administrativeFeePaymentDetermination,
  servicesAndUtilitiesPartyType,
  utilitiesAndServicesModalSteps,
  workflow,
} from '~/utils/constants';
import { Field, Form } from 'vee-validate';

export default {
  name: 'AgreementUtilitiesListItemView',
  components: { Form, Field },
  props: {
    service: {
      type: Object,
      default: () => {
        return {};
      },
    },
    viewMode: {
      type: String,
      default: '',
    },
    selectedServicesAndUtilities: {
      type: Array,
      default: () => {
        return [];
      },
    },
    agreementWorkflow: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: 'EUR',
    },
    agreementId: {
      type: String,
      default: '',
    },
  },
  emits: ['addService', 'updateService', 'deleteService', 'backButtonClicked'],

  data() {
    return {
      editableService: this.setToEmptyService(),
      selectedService: {
        label: this.$t('services.label.choose_service'),
        value: '',
      },
      customServiceName: '',
      payerRoleTabs: payerRoleTabsOptions(
        this.$t('services.label.tenant'),
        this.$t('services.label.landlord'),
      ),
      paymentTypeTabs: paymentTypeTabsOptions(
        this.$t('administrative_rent.dynamic_payment'),
        this.$t('administrative_rent.fixed_payment'),
      ),
      paymentDetermination: administrativeFeePaymentDetermination,
    };
  },
  computed: {
    paymentAmountModel: {
      get() {
        return this.editableService?.payment?.amount || null;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.editableService.payment.amount = normalized || null;
      },
    },

    isEditServiceView() {
      return this.viewMode === utilitiesAndServicesModalSteps.EDIT_SERVICE_VIEW;
    },

    isAddServiceView() {
      return this.viewMode === utilitiesAndServicesModalSteps.ADD_SERVICE_VIEW;
    },

    isFixedCost() {
      return (
        this.editableService?.paymentMethod ===
        administrativeFeePaymentDetermination.FIXED
      );
    },

    listOfServices() {
      const list = [];
      const fullList = Object.values(
        this.isPoland
          ? defaultPolandServicesAndUtilities()
          : fullListOfServicesAndUtilities(),
      );
      const filledList = [...this.selectedServicesAndUtilities];
      const listOfServicesToAdd = differenceBy(fullList, filledList, 'type');

      listOfServicesToAdd.sort((a, b) =>
        this.serviceName(a.type, a.typeName).localeCompare(
          this.serviceName(b.type, b.typeName),
        ),
      );

      listOfServicesToAdd.filter((fee) =>
        list.push({
          label: this.serviceName(fee.type, fee.typeName),
          value: fee.type,
        }),
      );
      // add other option
      list.push({
        label: this.$t('services.label.add_new_service'),
        value: 'OTHER',
      });
      return list;
    },

    isCustomService() {
      return startsWith(this.editableService.type, 'OTHER');
    },

    isPoland() {
      return this.agreementWorkflow === workflow.POLAND;
    },
  },

  watch: {
    selectedService: {
      handler(newVal) {
        if (newVal && this.editableService.type !== newVal?.value) {
          this.editableService = {
            ...this.editableService,
            ...fullListOfServicesAndUtilities()[newVal.value],
            type: newVal.value,
          };
        }
      },
    },

    service: {
      handler() {
        this.setEditableService();
      },
    },

    customServiceName: {
      handler(newVal) {
        if (this.editableService.typeName !== newVal) {
          this.editableService.typeName = newVal;
        }
      },
    },

    editableService: {
      handler(newVal) {
        if (
          newVal?.paymentMethod === administrativeFeePaymentDetermination.DYNAMIC &&
          newVal?.payment?.amount
        ) {
          this.editableService.payment = {
            amount: null,
            details: null,
          };
        }
        if (newVal?.type) {
          if (!newVal.paymentMethod) {
            this.editableService.paymentMethod =
              administrativeFeePaymentDetermination.DYNAMIC;
          }
          if (!newVal.whoIsPaying) {
            this.editableService.whoIsPaying = servicesAndUtilitiesPartyType.TENANT;
          }
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.setEditableService();
  },

  methods: {
    transformLocalToSourceService() {
      const sourceStructureService = {
        agreementFirebaseId: this.agreementId,
        whoIsPaying: this.editableService.whoIsPaying,
        typeName: this.editableService.typeName,
        type: this.editableService.type,
        paymentMethod: this.editableService.paymentMethod,
        payment: this.isFixedCost
          ? {
              amount: this.editableService.payment.amount,
              details: this.editableService.payment.details || '',
            }
          : null,
      };
      return sourceStructureService;
    },

    setEditableService() {
      if (this.service.type) {
        this.editableService = {
          ...this.service,
          payment: {
            amount: this.service.payment ? this.service.payment.amount : null,
            details: this.service.payment ? this.service.payment.details : null,
          },
        };
        this.selectedService = {
          label: this.serviceName(
            this.editableService.type,
            this.editableService.typeName,
          ),
          value: this.editableService.type,
        };
        if (this.isCustomService) {
          this.customServiceName = this.editableService.typeName;
        }
      } else {
        this.editableService = this.setToEmptyService();
        this.selectedService = {
          label: this.$t('services.label.choose_service'),
          value: '',
        };
        this.customServiceName = '';
      }
    },

    validateAndScrollToFailedValidation(validate) {
      validate().then((result) => {
        if (!result.valid) {
          const invalidField = Object.keys(result.results).find(
            (field) => !result.results[field].valid,
          );
          if (invalidField) {
            const fieldRef = this.$refs[invalidField];
            if (fieldRef && fieldRef.$el) {
              fieldRef.$el.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }
      });
    },

    emitSaveServiceClick() {
      if (this.isAddServiceView) {
        this.$emit('addService', { ...this.transformLocalToSourceService() });
      } else if (this.isEditServiceView) {
        this.$emit('updateService', { ...this.transformLocalToSourceService() });
      }
    },

    emitDeleteServiceClick() {
      this.$emit('deleteService', { ...this.transformLocalToSourceService() });
    },

    emitBackButtonClick() {
      this.$emit('backButtonClicked');
    },

    setToEmptyService() {
      return {
        typeName: null,
        whoIsPaying: null,
        payment: {
          amount: null,
          details: null,
        },
        type: null,
        paymentMethod: null,
      };
    },

    serviceName(key, name) {
      if (key && key.slice(0, 5) !== 'OTHER') {
        let predefinedServiceName = 'service.';
        predefinedServiceName = predefinedServiceName.concat(key.toLowerCase());
        return this.$t(predefinedServiceName);
      } else if (key && key.slice(0, 5) === 'OTHER') {
        return this.$t('service.other');
      } else {
        return name;
      }
    },
  },
};
</script>
