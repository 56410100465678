<template>
  <div class="advertisement-edit-view w-full">
    <r-tabs v-model="tabValue" :tabs="tabsData">
      <template #info>
        <div class="bg-white rounded-lg py-10">
          <h3 class="text-xl font-bold text-center mb-8">
            {{ blok.editListingInfoTitle }}
          </h3>

          <!-- Tabs for select if property is house or apartment or room-->
          <div class="max-w-sm mx-auto px-4 grid sm:grid-cols-2 gap-2 my-6">
            <div
              v-for="(tab, index) in propertyTypeData"
              :id="tab.tabId"
              :key="index"
              class="flex justify-center align-middle bg-white text-center rounded-xl p-4 border border-gray-300 hover:text-rendin-500 hover:border-rendin-500 focus:border-rendin-500"
              :class="{
                'text-rendin-500 border-rendin-500':
                  tab.propertyType === property.propertyType,
              }"
              @click="onSelectPropertyType(tab.propertyType)"
            >
              <r-icon
                v-if="tab.propertyType === adPropertyTypes.HOUSE"
                class="text-2xl mr-2"
                icon-name="house-day"
              />
              <r-icon
                v-else-if="tab.propertyType === adPropertyTypes.APARTMENT"
                class="text-2xl mr-2"
                icon-name="building"
              />
              <r-icon
                v-else-if="tab.propertyType === adPropertyTypes.ROOM"
                class="text-2xl mr-2"
                icon-name="door-open"
              />

              <h4 class="text-lg sm:font-medium text-gray-900">
                {{ tab.propertyLabel }}
              </h4>
            </div>
          </div>

          <VeeForm ref="observerInfo" v-slot="{ meta, handleSubmit }" tag="div">
            <form
              autocomplete="on"
              @submit.prevent="handleSubmit(() => updateAdvertisement(property))"
            >
              <div class="max-w-sm mx-auto px-4">
                <!-- address info -->
                <address-search-molecule
                  :address-apartment-disclaimer="blok.addressApartmentDisclaimer"
                  allow-hide-house-number
                  :external-address="fullAddressModel"
                  required
                  :source="source"
                  @input="fullAddressModel = $event"
                />
                <!-- floor info -->
                <div v-if="property.propertyType === adPropertyTypes.APARTMENT">
                  <Field
                    v-slot="{ meta }"
                    v-model="property.floor"
                    class="my-6"
                    name="floor"
                    rules="required|selectValueIsNumber"
                    tag="div"
                  >
                    <r-input
                      v-if="isMobileScreen"
                      v-model="property.floor"
                      field-type="tel"
                      :label="$t('form.label.floor')"
                      name="floor1"
                      :placeholder="$t('form.placeholder.floor')"
                      required
                      :validation-failed="meta.validated && !meta.valid"
                      :validation-passed="meta.validated && meta.valid"
                    />
                    <r-select
                      v-if="!isMobileScreen"
                      v-model="floorData"
                      class="hidden md:block"
                      :label="$t('form.label.floor')"
                      option-label="value"
                      :options="floorOptions"
                      :placeholder="$t('form.placeholder.floor')"
                      required
                      :validation-failed="meta.validated && !meta.valid"
                      :validation-passed="meta.validated && meta.valid"
                    />
                  </Field>

                  <Field
                    v-slot="{ meta }"
                    v-model="property.floorsTotal"
                    name="floorsTotal"
                    class="my-6"
                    rules="required|floorsTotalValidation:@floor|selectValueIsNumber"
                    tag="div"
                  >
                    <r-input
                      v-if="isMobileScreen"
                      v-model="property.floorsTotal"
                      field-type="tel"
                      :label="$t('form.label.floorsTotal')"
                      name="floorsTotal"
                      :placeholder="$t('form.placeholder.floorsTotal')"
                      required
                      :validation-failed="meta.validated && !meta.valid"
                      :validation-passed="meta.validated && meta.valid"
                    />
                    <r-select
                      v-if="!isMobileScreen"
                      v-model="floorsTotalData"
                      class="hidden md:block"
                      :label="$t('form.label.floorsTotal')"
                      name="floor"
                      option-label="value"
                      :options="filterFloorOptions(floorOptions, property.floor)"
                      :placeholder="$t('form.placeholder.floorsTotal')"
                      required
                      :validation-failed="meta.validated && !meta.valid"
                      :validation-passed="meta.validated && meta.valid"
                    />
                  </Field>
                </div>
                <!-- area info -->
                <Field
                  v-slot="{ meta }"
                  v-model="objectAreaModel"
                  name="area"
                  class="my-6"
                  :rules="{ required: true, isFloat: true }"
                  tag="div"
                >
                  <r-input
                    v-model="objectAreaModel"
                    add-on="m2"
                    field-type="tel"
                    inputmode="decimal"
                    :label="$t('form.label.area')"
                    name="area"
                    :placeholder="$t('form.placeholder.area')"
                    required
                    :validation-failed="meta.validated && !meta.valid"
                    :validation-passed="meta.validated && meta.valid"
                  />
                </Field>

                <!-- rooms info -->
                <Field
                  v-if="property.propertyType !== adPropertyTypes.ROOM"
                  v-slot="{ meta }"
                  v-model="property.numberOfRooms"
                  name="numberOfRooms"
                  class="my-6"
                  rules="required|numeric"
                  tag="div"
                >
                  <r-input
                    v-model="property.numberOfRooms"
                    field-type="tel"
                    :label="$t('form.label.numberOfRooms')"
                    name="numberOfRooms"
                    :placeholder="$t('form.placeholder.numberOfRooms')"
                    required
                    :validation-failed="meta.validated && !meta.valid"
                    :validation-passed="meta.validated && meta.valid"
                  />
                </Field>
              </div>

              <!-- additional info -->
              <div
                class="max-w-3xl mx-auto px-4 grid md:grid-cols-3 md:gap-4 items-start my-6"
              >
                <r-checkbox
                  id="hasParking"
                  v-model="property.hasParking"
                  name="parking"
                >
                  {{ $t('form.label.hasParking') }}
                </r-checkbox>
                <r-checkbox
                  id="hasStorage"
                  v-model="property.hasStorage"
                  name="hasStorage"
                >
                  {{ $t('form.label.hasStorage') }}
                </r-checkbox>
                <div>
                  <r-checkbox
                    id="petsAllowed"
                    v-model="property.petsAllowed"
                    name="petsAllowed"
                  >
                    {{ $t('form.label.petsAllowed') }}
                  </r-checkbox>
                  <span class="text-gray-500">
                    {{ $t('form.label.petsAllowedDescription') }}
                  </span>
                </div>
              </div>

              <div class="max-w-sm mx-auto px-4 h-14 mt-6">
                <r-button
                  :disabled="
                    !meta.valid || addDataLoading || additionalFeeChangeInProgress
                  "
                  type="submit"
                  width="full"
                  @click="closeManualAddressView"
                >
                  <span v-if="!addDataLoading">{{ $t('buttons.save') }}</span>
                  <span v-if="addDataLoading" class="flex">
                    <r-loader size="20" />
                  </span>
                </r-button>
              </div>
            </form>
          </VeeForm>
        </div>
      </template>

      <template #price>
        <div v-if="blok" class="bg-white rounded-lg py-10">
          <h3 class="text-xl font-bold text-center mb-8">
            {{ blok.editListingPriceTitle }}
          </h3>
          <VeeForm ref="validator" v-slot="{ meta: formMeta }" @submit.prevent>
            <Field
              ref="rentAmountProvider"
              v-slot="{ meta }"
              v-model="rentAmountModel"
              name="rent"
              :rules="{ required: true, isFloat: true }"
            >
              <div class="max-w-sm mx-auto px-4">
                <r-input
                  v-model="rentAmountModel"
                  :add-on="currencyLabel"
                  field-type="tel"
                  inputmode="decimal"
                  :label="$t('form.label.rentAmount')"
                  name="rent"
                  :placeholder="$t('form.placeholder.rentAmount')"
                  required
                  :validation-failed="meta.validated && !meta.valid"
                  :validation-passed="meta.validated && meta.valid"
                />
              </div>
            </Field>

            <div class="text-center font-medium px-4 mt-4">
              {{ blok.rendinFeePrefix }}
              <span class="text-rendin-500">
                {{
                  rendinFeeCalculation(
                    propertyRentAmount.rentAmount,
                    advertisementFromFirebase.workflow,
                  ).toLocaleString(getLocale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                {{ currencyLabel }}
              </span>
              {{ blok.rendinFeeSufix }}
            </div>
            <div class="max-w-3xl mx-auto text-center px-4">
              <component
                :is="theBlok.component"
                v-for="theBlok in blok.rendinDisclaimer.filter((item) =>
                  ['RichTextBlok'].includes(item.component),
                )"
                :key="theBlok._uid"
                :blok="theBlok"
              />
            </div>

            <r-inline-help-box
              v-if="blok.rendinFeeModal?.length > 0"
              class="px-4 pb-4 mx-auto max-w-sm"
              icon="circle-question"
              :title="$t('modals.rendin_fee')"
              width="full"
              @click="showRendinFeeModal = !showRendinFeeModal"
            />
            <r-overlay size="small" :visible="showModal" @close="closeModals()">
              <div class="pb-4">
                <div class="mt-8 mx-4 sm:mx-8">
                  <component
                    :is="theBlok.component"
                    v-for="theBlok in modalContent.filter((item) =>
                      ['RichTextBlok'].includes(item.component),
                    )"
                    :key="theBlok._uid"
                    :blok="theBlok"
                  />
                </div>

                <r-button
                  display="block"
                  :label="$t('buttons.close')"
                  no-background
                  uppercase
                  @click="closeModals()"
                />
              </div>
            </r-overlay>
            <div
              v-if="
                country.toUpperCase() === workflow.POLAND &&
                (blok.editAdditionalFeeTitle ||
                  (typeof blok.editAdditionalFeeContent === 'object' &&
                    blok.editAdditionalFeeContent?.content?.length > 0 &&
                    blok.editAdditionalFeeContent.content[0].content))
              "
            >
              <h3
                v-if="blok && blok.editAdditionalFeeTitle"
                class="text-xl font-bold text-center mb-8 mt-4"
              >
                {{ blok.editAdditionalFeeTitle }}
              </h3>

              <Field
                ref="additionalFeeAmountProvider"
                v-slot="{ meta }"
                v-model="additionalFeeModel"
                name="additionalFee"
                :rules="{ isFloat: true }"
              >
                <div class="max-w-sm mx-auto px-4">
                  <r-input
                    v-model="additionalFeeModel"
                    :add-on="currencyLabel"
                    field-type="tel"
                    inputmode="decimal"
                    :label="$t('offer.additional_fee.type.fixed_utilities.pl')"
                    name="rent"
                    :placeholder="$t('service.administration_fee')"
                    :validation-failed="meta.validated && !meta.valid"
                    :validation-passed="meta.validated && meta.valid"
                  />
                </div>
              </Field>
              <RichText
                v-if="
                  typeof blok.editAdditionalFeeContent === 'object' &&
                  blok.editAdditionalFeeContent?.content?.length > 0 &&
                  blok.editAdditionalFeeContent.content[0].content
                "
                :blok="blok.editAdditionalFeeContent"
                class="max-w-3xl mx-auto text-center px-4"
              />
              <r-inline-help-box
                v-if="blok.additionalFeeModalContent?.length > 0"
                class="p-4 mx-auto max-w-sm"
                icon="circle-question"
                :title="$t('modals.administrative_fee')"
                width="full"
                @click="showAdditionalFeeModal = !showAdditionalFeeModal"
              />
            </div>

            <div class="pt-4">
              <div v-if="blok.insuranceInvoiceToTitle?.length > 0">
                <component
                  :is="childBlok.component"
                  v-for="childBlok in blok.insuranceInvoiceToTitle.filter((item) =>
                    ['RichTextBlok'].includes(item.component),
                  )"
                  :key="childBlok._uid"
                  :blok="childBlok"
                  class="text-xl font-bold text-center"
                />
              </div>
              <div class="w-full flex justify-center max-w-sm mx-auto px-4">
                <r-radio
                  v-model="insuranceInvoiceTo"
                  class="w-full"
                  flavor="bordered"
                  name="insuranceInvoiceTo"
                  :options="insuranceInvoiceToOptions"
                />
              </div>
              <div
                v-if="blok.insuranceInvoiceToContent?.length > 0"
                class="max-w-3xl mx-auto text-center px-4 hidden sm:block"
              >
                <component
                  :is="childBlok.component"
                  v-for="childBlok in blok.insuranceInvoiceToContent.filter((item) =>
                    ['RichTextBlok'].includes(item.component),
                  )"
                  :key="childBlok._uid"
                  :blok="childBlok"
                  class="my-8 text-gray-500 text-center"
                />
              </div>
              <r-inline-help-box
                v-if="blok.insuranceInvoiceToModal?.length > 0"
                class="px-4 pb-4 mx-auto max-w-sm"
                icon="circle-question"
                :title="$t('modals.what_does_it_mean')"
                width="full"
                @click="showInsuranceInvoiceToModal = !showInsuranceInvoiceToModal"
              />
            </div>
            <div class="max-w-sm mx-auto px-4 h-14 mt-2 mb-4 px-4">
              <r-button
                :disabled="
                  !formMeta.valid || addDataLoading || additionalFeeChangeInProgress
                "
                width="full"
                @click="
                  formMeta.valid && !addDataLoading
                    ? updateAdvertisementPriceData({
                        ...propertyRentAmount,
                        insuranceInvoiceTo: insuranceInvoiceTo,
                      })
                    : null
                "
              >
                <span v-if="!addDataLoading">
                  {{ $t('buttons.save') }}
                </span>
                <span
                  v-if="addDataLoading || additionalFeeChangeInProgress"
                  class="flex"
                >
                  <r-loader size="20" />
                </span>
              </r-button>
            </div>
          </VeeForm>
        </div>
      </template>

      <template #description>
        <div class="bg-white rounded-lg px-4 py-10">
          <div class="max-w-3xl mx-auto">
            <h3 class="text-xl font-bold text-center mb-8">
              {{ blok.editListingDescriptionTitle }}
            </h3>

            <component
              :is="theBlok.component"
              v-for="theBlok in blok.descriptionContent.filter((item) =>
                ['RichTextBlok'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
            />

            <Field
              v-slot="{ meta }"
              v-model="propertyDescription.description"
              name="description"
              rules="required"
            >
              <r-textarea
                v-model="propertyDescription.description"
                :error-text="descriptionTriggersErrorMessage"
                :help-modal-label="blok.descriptionHelpModalLabel"
                highlight-triggers-words
                :label="$t('form.label.description')"
                :maxlength="blok.descriptionMaxLenght"
                name="description"
                :placeholder="$t('form.placeholder.description')"
                rows="12"
                :trigger-words-regex-array="descriptionTriggersRegex"
                :validation-failed="
                  (meta.validated && !meta.valid) || !!descriptionTriggersErrorMessage
                "
                :validation-passed="meta.validated && meta.valid"
              >
                <template #modal>
                  <component
                    :is="theBlok.component"
                    v-for="theBlok in blok.descriptionModal.filter((item) =>
                      ['RichTextBlok', 'AccordionGroup'].includes(item.component),
                    )"
                    :key="theBlok._uid"
                    :blok="theBlok"
                  />
                </template>
              </r-textarea>
            </Field>
            <div class="max-w-sm mx-auto mt-6">
              <r-button
                :disabled="addDataLoading || additionalFeeChangeInProgress"
                type="submit"
                width="full"
                @click="
                  !addDataLoading ? updateAdvertisement(propertyDescription) : null
                "
              >
                <span v-if="!addDataLoading">
                  {{ $t('buttons.save') }}
                </span>
                <span v-if="addDataLoading" class="flex">
                  <r-loader size="20" />
                </span>
              </r-button>
            </div>
          </div>
        </div>
      </template>

      <template #images>
        <div class="bg-white rounded-lg px-4 py-10">
          <div class="max-w-3xl mx-auto">
            <h3 class="text-xl font-bold text-center mb-8">
              {{ blok.editListingImageTitle }}
            </h3>
            <r-image-upload-cloudinary
              v-model="propertyImages.images"
              v-model:uploading-in-progress="imagesUploadingInProgress"
              :add-photo-button="blok.photosUploadAddPhotoButton"
              :brows-button-text="blok.photosUploadBrowseButton"
              :brows-label-text="blok.photosUploadBrowseLabel"
              :help-modal-label="blok.photosUploadHelpModalLabel"
              :max-file-size-error="blok.photosMaxFileSizeError"
              :max-upload-file-size="Number(blok.photosMaxUploadFileSize)"
              :min-image-height="Number(blok.photosMinImageHeight)"
              :min-image-size-error="blok.photosMinImageSizeError"
              :min-image-width="Number(blok.photosMinImageWidth)"
              :open-photo-label="blok.photosUploadOpenPhotoLabel"
            >
              <template v-if="blok.photosUploadHelpModal" #modal>
                <component
                  :is="theBlok.component"
                  v-for="theBlok in blok.photosUploadHelpModal.filter((item) =>
                    ['RichTextBlok', 'AccordionGroup'].includes(item.component),
                  )"
                  :key="theBlok._uid"
                  :blok="theBlok"
                />
              </template>
            </r-image-upload-cloudinary>

            <component
              :is="theBlok.component"
              v-for="theBlok in blok.photosRequirements.filter((item) =>
                ['RichTextBlok'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
            />

            <div class="max-w-sm mx-auto mt-6">
              <r-button
                :disabled="
                  addDataLoading ||
                  imagesUploadingInProgress ||
                  additionalFeeChangeInProgress
                "
                type="submit"
                width="full"
                @click="uploadAdvertisementImages"
              >
                <span v-if="addDataLoading" class="flex">
                  <r-loader size="20" />
                </span>
                <span v-else>
                  {{ $t('buttons.save') }}
                </span>
              </r-button>
            </div>
          </div>
        </div>
      </template>
    </r-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Form as VeeForm, Field } from 'vee-validate';
import { cloneDeep } from 'lodash';

import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation.js';
import {
  AdvertisementSourceType,
  additionalFeeType,
  additionalFeeInterval,
  webSourceType,
  workflow,
  adPropertyTypes,
  advertisementEditViewTabs,
} from '~/utils/constants';
import { censorshipEventNames } from '~/utils/trackerConstants';
import AddressSearchMolecule from '~/components/snippets/AddressSearchMolecule.vue';
import { parseFullAddressFromAddressParameters } from '~/utils/addressCollector.js';
import {
  depositRegexArray,
  getLocationFromDefaultAddressObject,
  payerRoleTabsOptions,
  shortTermRegexArray,
} from '~/utils/objectStructures';

export default {
  name: 'AdvertisementEditView',
  components: {
    VeeForm,
    Field,
    AddressSearchMolecule,
  },

  props: {
    blok: {
      type: Object,
      required: true,
    },
    advertisementFromFirebase: {
      type: Object,
      default: null,
    },
    moveToTab: {
      type: String,
      default: null,
    },
    isMobileScreen: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      rendinFeeCalculation,
      parseFullAddressFromAddressParameters,
    };
  },

  data() {
    return {
      showManualAddressContent: false,
      imagesUploadingInProgress: false,
      property: {
        propertyType: null,
        address: null,
        addressFirstLine: null,
        addressCity: null,
        addressStreet: null,
        addressCountry: null,
        addressIndex: null,
        addressHouseNumber: null,
        addressApartmentNumber: null,
        addressHouseNumberHidden: null,
        floor: null,
        floorsTotal: null,
        objectArea: '',
        numberOfRooms: null,
        hasParking: false,
        hasStorage: false,
        petsAllowed: false,
      },
      propertyRentAmount: {
        rentAmount: null,
      },
      propertyDescription: {
        description: null,
      },
      propertyImages: {
        images: [],
        status: null,
      },
      addressInput: {},
      floorData: [],
      floorsTotalData: [],

      tabsData: [
        {
          id: advertisementEditViewTabs.INFO,
          title: this.blok.mainInfoTab,
        },
        {
          id: advertisementEditViewTabs.PRICE,
          title: this.blok.priceTab,
        },
        {
          id: advertisementEditViewTabs.DESCRIPTION,
          title: this.blok.descriptionTab,
        },
        {
          id: advertisementEditViewTabs.IMAGES,
          title: this.blok.imageTab,
        },
      ],
      tabValue: advertisementEditViewTabs.INFO,
      addDataLoading: false,
      additionalFeeChangeInProgress: false,
      showRendinFeeModal: false,
      fixedUtilitiesFee: {
        amount: null,
        type: additionalFeeType.FIXED_UTILITIES,
        paymentInterval: additionalFeeInterval.MONTHLY,
      },
      advertisementFixedUtilitiesFee: {},
      workflow,
      source: webSourceType.WEB_ADVERTISEMENT_EDIT,
      webSourceType,
      insuranceInvoiceTo: null,
      insuranceInvoiceToOptions: payerRoleTabsOptions(
        this.$t('tenant'),
        this.$t('landlord'),
      ),
      showInsuranceInvoiceToModal: false,
      showAdditionalFeeModal: false,

      descriptionTriggersRegex: [...depositRegexArray(), ...shortTermRegexArray()],
      hasUserTriggeredDescriptionRegex: false,
    };
  },

  computed: {
    adPropertyTypes() {
      return adPropertyTypes;
    },
    ...mapGetters({
      hasSessionUser: 'users/hasSessionUser',
      isENVIsProduction: 'isENVIsProduction',
      currencyLabel: 'getCurrencyLabel',
      country: 'getCountry',
      getLocale: 'getLocale',

      putAdvertisementResponse: 'advertisements/putAdvertisementResponse',
      putAdvertisementInProgress: 'advertisements/putAdvertisementInProgress',
      putAdvertisementFailed: 'advertisements/putAdvertisementFailed',

      getAnalyticEventNames: 'tracker/getAnalyticEventNames',
      getAnalyticEventProperties: 'tracker/getAnalyticEventProperties',
    }),

    /* TODO - Implemented with getter & setter for less refactoring after Address Update, ideally not needed */
    fullAddressModel: {
      get() {
        return this.addressInput;
      },
      set(newVal) {
        if (newVal) {
          const finalValue = { ...this.addressInput, ...newVal };
          this.addressInput = finalValue;
        }
      },
    },

    objectAreaModel: {
      get() {
        return this.property.objectArea || null;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.property.objectArea = normalized;
      },
    },

    rentAmountModel: {
      get() {
        return this.propertyRentAmount.rentAmount || null;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.propertyRentAmount.rentAmount = normalized;
      },
    },

    additionalFeeModel: {
      get() {
        return this.fixedUtilitiesFee.amount || null;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.fixedUtilitiesFee.amount = normalized;
      },
    },
    floorOptions() {
      /* Return a list of floors from 0 to maxValue-1 */
      const MAX_FLOOR = 25;
      return [...Array(MAX_FLOOR).keys()].map((v) => ({
        value: v,
      }));
    },

    isFixedUtilitiesFeeAmountChanged() {
      if (
        this.advertisementFixedUtilitiesFee &&
        Object.keys(this.advertisementFixedUtilitiesFee)?.length !== 0
      ) {
        return (
          this.advertisementFixedUtilitiesFee.amount !== this.fixedUtilitiesFee.amount
        );
      } else {
        return !!this.fixedUtilitiesFee.amount;
      }
    },

    showModal() {
      return (
        this.showInsuranceInvoiceToModal ||
        this.showAdditionalFeeModal ||
        this.showRendinFeeModal
      );
    },

    modalContent() {
      if (this.showRendinFeeModal && this.blok.rendinFeeModal) {
        const content = [];

        content.push(...this.blok.rendinFeeModal);

        if (
          this.advertisementFromFirebase.propertyType === adPropertyTypes.ROOM &&
          this.blok.propertyDamagesProtectionModalRoomAddon
        ) {
          content.push(...this.blok.propertyDamagesProtectionModalRoomAddon);
        }

        return content;
      } else if (
        this.showInsuranceInvoiceToModal &&
        this.blok.insuranceInvoiceToModal
      ) {
        return this.blok.insuranceInvoiceToModal;
      } else if (this.showAdditionalFeeModal && this.blok.additionalFeeModalContent) {
        return this.blok.additionalFeeModalContent;
      } else {
        return [];
      }
    },

    descriptionTriggeredRegex() {
      const triggeredRegex = [];
      this.descriptionTriggersRegex.forEach((regexItem) => {
        if (regexItem.regex.test(this.propertyDescription.description)) {
          if (!triggeredRegex.find((item) => item.key === regexItem.key)) {
            triggeredRegex.push(regexItem);
          }
        }
      });
      return triggeredRegex.length > 0 ? triggeredRegex : null;
    },

    descriptionTriggersErrorMessage() {
      if (this.descriptionTriggeredRegex) {
        const errorMessagesArray = this.descriptionTriggeredRegex.map(
          (item) => item.errorMessage,
        );
        const errorMessagesString = errorMessagesArray.join(', ');
        return `${this.$t('censorship.general')} ${errorMessagesString}`;
      }
      return null;
    },

    propertyTypeData() {
      const options = [];

      options.push({
        propertyType: adPropertyTypes.HOUSE,
        propertyLabel: this.blok.propertyTypeHouseTitle,
      });

      options.push({
        propertyType: adPropertyTypes.APARTMENT,
        propertyLabel: this.blok.propertyTypeApartmentTitle,
      });

      if (this.country === 'pl') {
        options.push({
          propertyType: adPropertyTypes.ROOM,
          propertyLabel: this.blok.propertyTypeRoomTitle,
        });
      }

      return options;
    },
  },

  watch: {
    descriptionTriggeredRegex(newVal) {
      if (newVal && !this.hasUserTriggeredDescriptionRegex) {
        this.hasUserTriggeredDescriptionRegex = true;
        const eventName = censorshipEventNames.USED;
        const properties = {
          source: webSourceType.WEB_ADVERTISEMENT_EDIT,
          triggerKey: newVal.key,
          regex: newVal.regex,
          content: this.propertyDescription.description,
        };
        this.actionTrackListingDescriptionTrigger({
          eventName: eventName,
          props: properties,
        });
      }
    },

    addressInput(val) {
      if (val) {
        this.property.addressFirstLine = val.addressFirstLine;
        this.property.addressCity = val.addressCity;
        this.property.addressStreet = val.addressStreet;
        this.property.addressCountry = val.addressCountry;
        this.property.addressIndex = val.addressIndex;
        this.property.addressHouseNumber = val.addressHouseNumber;
        this.property.addressApartmentNumber = val.addressApartmentNumber;
        this.property.addressHouseNumberHidden = val.addressHouseNumberHidden;
      }
    },

    advertisementFromFirebase(newVal) {
      if (newVal.status !== 'DRAFT') {
        this.propertyImages.status = this.advertisementFromFirebase.status;
      }
    },

    tabValue(value) {
      if (value === advertisementEditViewTabs.INFO) {
        this.$refs.observerInfo.validate();
      }
      if (value === advertisementEditViewTabs.PRICE) {
        this.$refs.rentAmountProvider.validate();
      }

      // Track edit view tabs change events
      this.trackEditTabs(value);
    },

    phoneNumberData(val) {
      this.property.contactPhoneNumber = val.fullNumber;
    },

    floorData(val) {
      if (val && typeof val.value === 'number') {
        this.property.floor = val.value;
      } else {
        this.property.floor = null;
      }
    },

    floorsTotalData(val) {
      if (val && val.value) {
        this.property.floorsTotal = val.value;
      } else {
        this.property.floorsTotal = null;
      }
    },

    'property.floor': function (newVal) {
      if (Number.isFinite(parseInt(newVal))) {
        this.property.floor = parseInt(newVal);
      } else if (newVal) {
        this.floorData.value = newVal;
      } else {
        this.floorData = [];
      }
    },

    'property.floorsTotal': function (newVal) {
      if (Number.isFinite(parseInt(newVal))) {
        this.property.floorsTotal = parseInt(newVal);
      } else if (newVal) {
        this.floorsTotalData.value = newVal;
      } else {
        this.floorsTotalData = [];
      }
    },

    // Change status if images added
    'propertyImages.images': function (newVal) {
      const minNoOfImages = Number(this.blok.minNoOfImages) || 0;
      if (newVal.length < minNoOfImages) {
        this.propertyImages.status = 'DRAFT';
      } else if (this.propertyImages.status === 'DRAFT') {
        this.propertyImages.status = 'ACTIVE';
      }
    },
  },

  mounted() {
    this.fillEditModeData();
    this.getAdvertisement();

    this.tabValue = this.moveToTab || advertisementEditViewTabs.INFO;
  },

  methods: {
    ...mapActions({
      actionGetAdvertisement: 'advertisements/getAdvertisement',
      actionPutAdvertisement: 'advertisements/putAdvertisement',
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
      actionTrackDigibrokerEvent: 'tracker/trackDigibrokerEvent',
      actionPostAdvertisementAdditionalFee:
        'advertisements/postAdvertisementAdditionalFee',
      actionPutAdvertisementAdditionalFee:
        'advertisements/putAdvertisementAdditionalFee',
      actionDeleteAdvertisementAdditionalFee:
        'advertisements/deleteAdvertisementAdditionalFee',
      trackManualAddressAdded: 'tracker/trackManualAddressAdded',
      actionTrackListingDescriptionTrigger: 'tracker/trackListingDescriptionTrigger',
    }),

    uploadAdvertisementImages() {
      if (this.addDataLoading || this.imagesUploadingInProgress) {
        return null;
      } else if (this.propertyImages.status === 'DRAFT') {
        return this.updateAdvertisement(this.propertyImages);
      } else {
        return this.updateAdvertisement({ images: this.propertyImages.images });
      }
    },

    postAdvertisementAdditionalFee(additionalFee) {
      return this.actionPostAdvertisementAdditionalFee({
        advertisementId: this.advertisementFromFirebase.id,
        additionalFeeType: additionalFee.type,
        paymentInterval: additionalFee.paymentInterval,
        amount: additionalFee.amount,
        source: webSourceType.WEB_ADVERTISEMENT_EDIT,
      });
    },

    putAdvertisementAdditionalFee(additionalFee) {
      return this.actionPutAdvertisementAdditionalFee({
        advertisementId: this.advertisementFromFirebase.id,
        additionalFeeId: additionalFee.id,
        source: webSourceType.WEB_ADVERTISEMENT_EDIT,
        amount: additionalFee.amount,
      });
    },

    deleteAdvertisementAdditionalFee(additionalFee) {
      return this.actionDeleteAdvertisementAdditionalFee({
        advertisementId: this.advertisementFromFirebase.id,
        additionalFeeId: additionalFee.id,
        source: webSourceType.WEB_ADVERTISEMENT_EDIT,
      });
    },

    getAdvertisement() {
      if (this.hasSessionUser) {
        if (this.$route.params.objectid) {
          this.actionGetAdvertisement({ id: this.$route.params.objectid })
            .then(() => {
              this.fillEditModeData();
            })
            .catch((err) => this.actionReportErrorToSentry(err));
        } else {
          this.$router.push({
            path: this.$localizedPath(this.blok.backButtonLink.cached_url),
          });
        }
      } else {
        this.$router.push({ path: this.$localizedPath('/?login=open') });
      }
    },

    fillEditModeData() {
      if (this.hasSessionUser) {
        // main info
        this.property.propertyType = this.advertisementFromFirebase.propertyType;
        this.addressInput = {
          addressFirstLine: this.advertisementFromFirebase.addressFirstLine,
          addressCity: this.advertisementFromFirebase.addressCity,
          addressStreet: this.advertisementFromFirebase.addressStreet,
          addressCountry: this.advertisementFromFirebase.addressCountry,
          addressIndex: this.advertisementFromFirebase.addressIndex,
          addressHouseNumber: this.advertisementFromFirebase.addressHouseNumber,
          addressApartmentNumber: this.advertisementFromFirebase.addressApartmentNumber,
          hasApartmentNumber: !!this.advertisementFromFirebase.addressApartmentNumber,
          addressHouseNumberHidden:
            !!this.advertisementFromFirebase.addressHouseNumberHidden,
        };
        this.floorData =
          this.advertisementFromFirebase.propertyType ===
            this.adPropertyTypes.APARTMENT &&
          typeof this.advertisementFromFirebase.floor === 'number'
            ? { value: this.advertisementFromFirebase.floor }
            : null;
        this.floorsTotalData =
          this.advertisementFromFirebase.propertyType ===
            this.adPropertyTypes.APARTMENT && this.advertisementFromFirebase.floorsTotal
            ? { value: this.advertisementFromFirebase.floorsTotal }
            : null;
        this.objectAreaModel = this.advertisementFromFirebase.objectArea
          ? this.advertisementFromFirebase.objectArea.toString()
          : '';
        this.property.numberOfRooms = this.advertisementFromFirebase.numberOfRooms
          ? this.advertisementFromFirebase.numberOfRooms
          : null;
        this.property.hasParking = this.advertisementFromFirebase.hasParking
          ? this.advertisementFromFirebase.hasParking
          : null;
        this.property.hasStorage = this.advertisementFromFirebase.hasStorage
          ? this.advertisementFromFirebase.hasStorage
          : null;
        this.property.petsAllowed = this.advertisementFromFirebase.petsAllowed
          ? this.advertisementFromFirebase.petsAllowed
          : null;
        this.insuranceInvoiceTo = this.advertisementFromFirebase.insuranceInvoiceTo
          ? this.advertisementFromFirebase.insuranceInvoiceTo
          : 'TENANT';

        // rentAmount
        this.propertyRentAmount.rentAmount = this.advertisementFromFirebase.rentAmount
          ? this.advertisementFromFirebase.rentAmount
          : null;

        // Additional fee ( only 'FIXED_UTILITIES" type)
        if (this.advertisementFromFirebase.additionalFees) {
          const advertisementFixedFees =
            this.advertisementFromFirebase.additionalFees.filter(
              (additionalFee) =>
                additionalFee.type === additionalFeeType.FIXED_UTILITIES,
            );
          if (advertisementFixedFees.length > 0) {
            this.fixedUtilitiesFee = cloneDeep(advertisementFixedFees[0]);
            this.advertisementFixedUtilitiesFee = cloneDeep(advertisementFixedFees[0]);
          }
        }

        // description
        this.propertyDescription.description = this.advertisementFromFirebase
          .description
          ? this.advertisementFromFirebase.description
          : null;

        // images
        this.propertyImages.images = this.advertisementFromFirebase.images
          ? [].concat(this.advertisementFromFirebase.images)
          : [];
        this.propertyImages.status = this.advertisementFromFirebase.status
          ? this.advertisementFromFirebase.status
          : null;
      }
    },

    updateAdvertisementAdditionalFee() {
      this.additionalFeeChangeInProgress = true;
      if (!this.fixedUtilitiesFee.amount && this.advertisementFixedUtilitiesFee.id) {
        this.deleteAdvertisementAdditionalFee(this.fixedUtilitiesFee)
          .then(() => {
            this.advertisementFixedUtilitiesFee = {};
            this.additionalFeeChangeInProgress = false;
          })
          .catch((err) => this.actionReportErrorToSentry(err));
      } else if (!this.advertisementFixedUtilitiesFee.amount) {
        this.postAdvertisementAdditionalFee(this.fixedUtilitiesFee)
          .then((result) => {
            this.fixedUtilitiesFee.id = result.response;
            this.advertisementFixedUtilitiesFee = { ...this.fixedUtilitiesFee };
            this.additionalFeeChangeInProgress = false;
          })
          .catch((err) => this.actionReportErrorToSentry(err));
      } else {
        this.putAdvertisementAdditionalFee(this.fixedUtilitiesFee)
          .then(() => {
            this.advertisementFixedUtilitiesFee = { ...this.fixedUtilitiesFee };
            this.additionalFeeChangeInProgress = false;
          })
          .catch((err) => this.actionReportErrorToSentry(err));
      }
    },

    updateAdvertisementPriceData(priceData) {
      if (this.isFixedUtilitiesFeeAmountChanged) {
        this.updateAdvertisementAdditionalFee();
      }
      this.updateAdvertisement(priceData);
    },

    async updateAdvertisement(data) {
      this.addDataLoading = true;
      if (this.fullAddressModel.internalUserEditedAddressManually) {
        await this.trackManualAddressAdded({
          addressCity: this.fullAddressModel.addressCity,
          addressStreet: this.fullAddressModel.addressStreet,
          addressPostalCode: this.fullAddressModel.addressIndex,
          addressHouseNumber: this.fullAddressModel.addressHouseNumber,
          addressCountry: this.fullAddressModel.addressCountry,
          addressHouseNumberHidden: this.fullAddressModel.addressHouseNumberHidden,
          addressApartmentNumber: this.fullAddressModel.addressApartmentNumber,
          addressFirstLine: this.fullAddressModel.addressFirstLine,
          source: this.source,
        });
      }

      if (
        this.tabValue === advertisementEditViewTabs.DESCRIPTION &&
        this.hasUserTriggeredDescriptionRegex
      ) {
        if (!this.descriptionTriggeredRegex) {
          const eventName = censorshipEventNames.SUCCESS;
          const properties = {
            source: webSourceType.WEB_ADVERTISEMENT_EDIT,
            content: this.propertyDescription.description,
            advertisementId: this.advertisementFromFirebase.id,
          };
          this.actionTrackListingDescriptionTrigger({
            eventName: eventName,
            props: properties,
          });
        } else {
          const eventName = censorshipEventNames.IGNORED;
          const properties = {
            source: webSourceType.WEB_ADVERTISEMENT_EDIT,
            triggerKey: this.descriptionTriggeredRegex.key,
            regex: this.descriptionTriggeredRegex.regex,
            content: this.propertyDescription.description,
            advertisementId: this.advertisementFromFirebase.id,
          };
          this.actionTrackListingDescriptionTrigger({
            eventName: eventName,
            props: properties,
          });
        }
      }
      this.actionPutAdvertisement({
        id: this.advertisementFromFirebase.id,
        advertisement: {
          ...data,
          addressCityDistrict: this.fullAddressModel.addressCityDistrict
            ? this.fullAddressModel.addressCityDistrict
            : undefined,
          location: getLocationFromDefaultAddressObject(
            this.fullAddressModel,
            this.fullAddressModel.internalUserEditedAddressManually,
          ),
        },
        source: AdvertisementSourceType.WEB_ADVERTISEMENT_EDIT,
      }).finally(() => (this.addDataLoading = false));
    },

    filterFloorOptions(selectOptionsArray, minValue) {
      const minOption = minValue || 1;
      return selectOptionsArray.filter((x) => x.value >= minOption);
    },

    trackEditTabs(tab) {
      const eventName = this.mapEditTabsEventNames(tab);

      const eventProperties = {
        [this.getAnalyticEventProperties.ADVERTISEMENT_KEY]: this
          .advertisementFromFirebase.id
          ? this.advertisementFromFirebase.id
          : null,
      };
      if (eventName) {
        this.actionTrackDigibrokerEvent({
          eventName,
          props: eventProperties,
        });
      }
    },

    mapEditTabsEventNames(tab) {
      if (tab === advertisementEditViewTabs.INFO) {
        return this.getAnalyticEventNames.ADVERTISEMENT_CHANGE_PROPERTY;
      } else if (tab === advertisementEditViewTabs.PRICE) {
        return this.getAnalyticEventNames.ADVERTISEMENT_CHANGE_PRICE;
      } else if (tab === advertisementEditViewTabs.DESCRIPTION) {
        return this.getAnalyticEventNames.ADVERTISEMENT_CHANGE_DESCRIPTION;
      } else if (tab === advertisementEditViewTabs.IMAGES) {
        return this.getAnalyticEventNames.ADVERTISEMENT_CHANGE_IMAGES;
      }
    },

    closeManualAddressView() {
      if (this.showManualAddressContent) {
        this.showManualAddressContent = false;
        this.trackManualAddressAdded({
          addressCity: this.property.addressCity,
          addressStreet: this.property.addressStreet,
          addressPostalCode: this.property.addressIndex,
          addressHouseNumber: this.property.addressHouseNumber,
          addressCountry: this.property.addressCountry,
          addressHouseNumberHidden: this.property.addressHouseNumberHidden,
          addressApartmentNumber: this.property.addressApartmentNumber,
          addressFirstLine: this.property.addressFirstLine,
          source: this.source,
        });
      }
    },

    onSelectPropertyType(type) {
      this.property.propertyType = type;

      if (type === adPropertyTypes.ROOM) {
        this.property.numberOfRooms = 1;
      }
    },

    closeModals() {
      this.showRendinFeeModal = false;
      this.showInsuranceInvoiceToModal = false;
      this.showAdditionalFeeModal = false;
    },
  },
};
</script>
