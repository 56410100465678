<template>
  <section class="agreement-edit-view-signing-info">
    <div class="bg-white rounded-lg shadow-lg p-4 md:px-10">
      <div class="text-rendin-700 uppercase font-medium mt-4 mb-2">
        {{ $t('agreement.signing_tab.preparing_to_sign') }}
      </div>

      <div class="grid sm:grid-cols-3 gap-4 items-center mb-4">
        <div class="sm:col-span-2 text-gray-600">
          {{ $t('agreement.signing_tab.before_signing') }}
        </div>
        <div>
          <r-button
            icon="file-pdf"
            icon-class="mr-3"
            inverted
            :is-loading="pdfButtonLoading"
            :label="$t('agreement.signing_tab.buttons.pdf')"
            size="small"
            width="full"
            @click="linkToAgreementPreviewPdf()"
          />
        </div>
      </div>
    </div>

    <div class="bg-white rounded-lg shadow-lg p-4 md:px-10 my-2">
      <div class="text-rendin-700 uppercase font-medium mt-4 mb-2">
        {{ $t('agreement.signing_tab.share_tenant_title') }}
      </div>

      <div class="grid sm:grid-cols-3 gap-4 items-center mb-4">
        <div class="sm:col-span-2 text-gray-600">
          {{ $t('agreement.signing_tab.share_tenant_sub') }}
        </div>
        <div>
          <r-button
            icon="share-nodes"
            icon-class="mr-3"
            inverted
            :label="$t('agreement.signing_tab.buttons.share')"
            size="small"
            width="full"
            @click="emitOpenSharingModalRequest()"
          />
        </div>
      </div>
    </div>

    <div class="bg-white rounded-lg shadow-lg p-4 md:px-10 my-2">
      <div class="text-rendin-700 uppercase font-medium my-4">
        {{ $t('agreement.signing_tab.next_steps_title') }}
      </div>
      <div class="mb-4">
        <r-accordion-group
          :active-index="accordionGroupActiveIndex"
          flavor="flat"
          :accordions="signingSteps"
          @switch="(index) => (accordionGroupActiveIndex = index)"
        >
          <template v-for="accordion in signingSteps" #[accordion.id]>
            {{ accordion.text }}
          </template>
        </r-accordion-group>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { constructDateFromIsoString } from '~/utils/dateFromIsoStringConstructor';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';
import { isoLanguageCode } from '~/utils/constants';

export default {
  name: 'AgreementEditViewSigningInfo',
  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
  },
  emits: ['openSharingModal'],

  setup() {
    return {
      constructDateFromIsoString,
    };
  },

  data: (vm) => ({
    agreementTrackingEventNames: agreementTrackingEventNames,
    trackingProcessNames: trackingProcessNames,
    pdfButtonLoading: false,
    accordionGroupActiveIndex: 0,
    signingStepsEE: [
      {
        id: 'step1',
        title: vm.$t('agreement.signing_tab.ee.title_1'),
        text: vm.$t('agreement.signing_tab.ee.subtitle_1'),
      },
      {
        id: 'step2',
        title: vm.$t('agreement.signing_tab.ee.title_2'),
        text: vm.$t('agreement.signing_tab.ee.subtitle_2'),
      },
      {
        id: 'step3',
        title: vm.$t('agreement.signing_tab.ee.title_3'),
        text: vm.$t('agreement.signing_tab.ee.subtitle_3'),
      },
      {
        id: 'step4',
        title: vm.$t('agreement.signing_tab.ee.title_4'),
        text: vm.$t('agreement.signing_tab.ee.subtitle_4'),
      },
    ],
    signingStepsPL: [
      {
        id: 'step1',
        title: vm.$t('agreement.signing_tab.pl.title_1'),
        text: vm.$t('agreement.signing_tab.pl.subtitle_1'),
      },
      {
        id: 'step2',
        title: vm.$t('agreement.signing_tab.pl.title_2'),
        text: vm.$t('agreement.signing_tab.pl.subtitle_2'),
      },
      {
        id: 'step3',
        title: vm.$t('agreement.signing_tab.pl.title_3'),
        text: vm.$t('agreement.signing_tab.pl.subtitle_3'),
      },
      {
        id: 'step4',
        title: vm.$t('agreement.signing_tab.pl.title_4'),
        text: vm.$t('agreement.signing_tab.pl.subtitle_4'),
      },
      {
        id: 'step5',
        title: vm.$t('agreement.signing_tab.pl.title_5'),
        text: vm.$t('agreement.signing_tab.pl.subtitle_5'),
      },
    ],
  }),

  computed: {
    ...mapGetters({
      getGlobalAppButtonLinksAndImages:
        'storyBlokGlobals/getGlobalAppButtonLinksAndImages',
    }),

    signingSteps() {
      return this.agreementFromFirebase.workflow === 'PL'
        ? this.signingStepsPL
        : this.signingStepsEE;
    },
  },

  mounted() {
    this.actionGetGlobalAppButtonLinksAndImagesFromStoryblok();
  },

  methods: {
    ...mapActions({
      actionGetGlobalAppButtonLinksAndImagesFromStoryblok:
        'storyBlokGlobals/getGlobalAppButtonLinksAndImagesFromStoryblok',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    linkToAgreementPreviewPdf() {
      if (this.agreementId && this.agreementFromFirebase?.agreementId) {
        window.open(this.agreementPreviewPdfTrustfulLink(), '_blank');
      } else if (this.agreementId) {
        this.pdfButtonLoading = true;
        this.actionGetAgreement({ id: this.agreementId }).then(() => {
          this.pdfButtonLoading = false;

          if (this.agreementFromFirebase?.agreementId) {
            window.open(this.agreementPreviewPdfTrustfulLink(), '_blank');
          } else {
            window.open(this.agreementDemoPdfLink(), '_blank');
          }
        });
      } else {
        window.open(this.agreementDemoPdfLink(), '_blank');
      }

      this.emitAgreementTrackerEvent(this.agreementTrackingEventNames.OPEN_PREVIEW);
    },

    agreementDemoPdfLink() {
      const getCloudHttpRequestURLApiType =
        'https://europe-west1-rendin.cloudfunctions.net/getDocumentPreview/AGREEMENT/';
      const lang = isoLanguageCode[this.getLocale];
      const country = this.country.toUpperCase();
      const format = 'format=html';

      if (this.selectedAgreementTypeData && this.country === 'pl') {
        return `${getCloudHttpRequestURLApiType + lang}?workflow=${country}&specifier=${this.selectedAgreementTypeData}&${format}`;
      } else {
        return `${getCloudHttpRequestURLApiType + lang}?${format}`;
      }
    },

    agreementPreviewPdfTrustfulLink() {
      if (this.agreementId && this.agreementFromFirebase?.agreementId) {
        const host = window.location.host;
        const agreementId = this.agreementFromFirebase.agreementId;
        const id = this.agreementId.substring(0, 7);
        const secureProtocol = host.includes('localhost:') ? 'http://' : 'https://';
        return `${secureProtocol + host}/agreement_preview/${agreementId}/${id}`;
      }
    },

    emitOpenSharingModalRequest() {
      this.$emit('openSharingModal');
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
};
</script>
