<template>
  <section class="agreement-edit-view-details">
    <Form ref="detailsDataForm" autocomplete="on">
      <!--      <form autocomplete="on" @submit.prevent>-->
      <!--   1. Agreement parameters  -->
      <div class="bg-white rounded-lg shadow-lg p-4 md:px-10">
        <div class="text-rendin-700 uppercase font-medium my-4">
          {{ $t('agreement.label.agreement_parameters') }}
        </div>

        <!--     Agreement type for PL -->
        <div
          v-if="agreementFromFirebase.workflow === workflow.POLAND"
          class="my-4 p-4 rounded-lg bg-peach-100 flex flex-col sm:flex-row justify-between sm:items-center gap-4"
        >
          <div class="flex items-center">
            <div>
              <div class="text-gray-900 font-medium">
                <span
                  v-if="
                    agreementFromFirebase.agreementTypeFromAgreement ===
                    agreementTypes.PL_NO
                  "
                >
                  {{ $t('agreement.agreementTypeSelect.pl_no.title') }}
                </span>
                <span
                  v-else-if="
                    agreementFromFirebase.agreementTypeFromAgreement ===
                    agreementTypes.PL_NI
                  "
                >
                  {{ $t('agreement.agreementTypeSelect.pl_ni.title') }}
                </span>
                <span
                  v-else-if="
                    agreementFromFirebase.agreementTypeFromAgreement ===
                    agreementTypes.PL_RE
                  "
                >
                  {{ $t('agreement.agreementTypeSelect.pl_regular.title') }}
                </span>
                <span v-else>
                  {{ $t('agreement.agreementTypeSelect.unknown.title') }}
                </span>
              </div>
              <div class="text-sm text-gray-600">
                <span
                  v-if="
                    agreementFromFirebase.agreementTypeFromAgreement ===
                    agreementTypes.PL_NO
                  "
                >
                  {{ $t('agreement.agreementTypeSelect.pl_no.description') }}
                </span>
                <span
                  v-else-if="
                    agreementFromFirebase.agreementTypeFromAgreement ===
                    agreementTypes.PL_NI
                  "
                >
                  {{ $t('agreement.agreementTypeSelect.pl_ni.description') }}
                </span>
                <span
                  v-else-if="
                    agreementFromFirebase.agreementTypeFromAgreement ===
                    agreementTypes.PL_RE
                  "
                >
                  {{ $t('agreement.agreementTypeSelect.pl_regular.description') }}
                </span>
                <span v-else>
                  {{ $t('agreement.agreementTypeSelect.unknown.description') }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex-shrink-0">
            <r-button
              border-color="light-gray"
              class="w-full sm:w-auto"
              color="black"
              inverted
              :label="$t('buttons.manage')"
              size="small"
              @click="openAgreementTypeModalPL()"
            />
          </div>
        </div>

        <!--    Agreement type for EE-->
        <div
          v-if="
            detailsData.contractType &&
            agreementFromFirebase.workflow === workflow.ESTONIA
          "
          class="my-4 p-4 rounded-lg bg-peach-100 flex flex-col sm:flex-row justify-between sm:items-center gap-4"
        >
          <div>
            <div class="text-gray-900 font-medium">
              <span v-if="detailsData.agreementType === agreementTypes.EE_BASIC">
                {{ $t('form.label.agreement_type_ee_basic') }}
              </span>
              <span
                v-else-if="detailsData.agreementType === agreementTypes.EE_LUMIKODUD"
              >
                {{ $t('form.label.agreement_type_ee_lumikodu') }}
              </span>
              <span v-else-if="detailsData.agreementType === agreementTypes.PL_NO">
                {{ $t('form.label.agreement_type_pl_no') }}
              </span>
              <span v-else-if="detailsData.agreementType === agreementTypes.PL_NI">
                {{ $t('form.label.agreement_type_pl_ni') }}
              </span>
              <span v-else-if="detailsData.agreementType === agreementTypes.UNKNOWN">
                {{ $t('form.label.agreement_type_unknown') }}
              </span>
            </div>

            <div class="text-sm text-gray-600">
              <span v-if="detailsData.agreementType === agreementTypes.EE_BASIC">
                {{ $t('agreement.disclaimer.agreement_type_ee_basic_info') }}
              </span>
              <span
                v-else-if="detailsData.agreementType === agreementTypes.EE_LUMIKODUD"
              >
                {{ $t('agreement.disclaimer.agreement_type_ee_lumikodu_info') }}
              </span>
              <span v-else-if="detailsData.agreementType === agreementTypes.PL_NO">
                {{ $t('agreement.disclaimer.agreement_type_pl_no_info') }}
              </span>
              <span v-else-if="detailsData.agreementType === agreementTypes.PL_NI">
                {{ $t('agreement.disclaimer.agreement_type_pl_ni_info') }}
              </span>
              <span v-else-if="detailsData.agreementType === agreementTypes.UNKNOWN">
                {{ $t('agreement.disclaimer.agreement_type_unknown_info') }}
              </span>
            </div>
          </div>

          <div class="flex-shrink-0">
            <r-button
              v-if="detailsData.agreementType !== agreementTypes.EE_LUMIKODUD"
              border-color="light-gray"
              class="w-full sm:w-auto"
              color="black"
              inverted
              :label="$t('read_more')"
              size="small"
              @click="openAgreementTypeModalEE()"
            />
          </div>
        </div>

        <!--    Agreement language-->
        <div class="my-4 mt-8">
          <r-country-select
            v-model="agreementLanguageSelect"
            country-name
            :label="$t('agreement.label.agreement_lang')"
            name="language"
            :options="
              getCountry === 'pl'
                ? agreementLanguageOptionsPL
                : agreementLanguageOptionsEE
            "
            @input="
              validateAndPutAgreement({ language: agreementLanguageSelect.language })
            "
          />
        </div>

        <div
          class="my-4"
          :class="{
            'grid sm:grid-cols-2 gap-4 ': detailsData.contractType === 'FIXED',
          }"
        >
          <!--    Agreement start date-->
          <div class="flex items-start gap-2">
            <div class="flex-grow">
              <Field
                ref="propertyHandoverDateRef"
                v-slot="{ meta }"
                v-model="detailsData.propertyHandoverDate"
                rules="required|isDateInFuture"
                name="propertyHandoverDate"
              >
                <LazyRDatepicker
                  v-model="detailsData.propertyHandoverDate"
                  name="propertyHandoverDate"
                  :disabled-date="disabledDatepickerDatesBeforeToday"
                  :error-text="$t('form.validation.pick_date')"
                  :label="$t('agreement.label.property_handover_date')"
                  required
                  :validation-failed="
                    (meta?.validated && !meta?.valid && meta?.touched) ||
                    (meta?.validated &&
                      !meta?.valid &&
                      !!detailsData.propertyHandoverDate) ||
                    (meta?.validated && !meta?.valid && validationExtraAttention)
                  "
                  :validation-passed="meta?.validated && meta?.valid"
                  @input="
                    validateAndPutAgreement(
                      {
                        startDate: detailsData.propertyHandoverDate,
                        handoverDate: detailsData.propertyHandoverDate,
                      },
                      'propertyHandoverDateRef',
                    );
                    meta.touched = true;
                  "
                />
              </Field>
            </div>
            <r-button
              class="mt-9"
              color="gray"
              no-background
              no-padding
              @click="openPropertyHandoverDateInfo()"
            >
              <r-icon class="text-2xl" icon-name="circle-question" />
            </r-button>
          </div>
          <!--    Agreement end date-->
          <div
            v-if="detailsData.contractType === agreementContractTypes.FIXED"
            class="flex items-start gap-2"
          >
            <div class="flex-grow">
              <Field
                ref="endDateRef"
                v-slot="{ meta }"
                v-model="detailsData.endDate"
                name="propertyEndDate"
                :rules="{
                  required: true,
                  isDateBetween: [
                    calculateDateIsoStringFromMonths(
                      detailsData.propertyHandoverDate,
                      fixedTermAgreementMinLengthInMonths,
                    ),
                    calculateDateIsoStringFromMonths(
                      detailsData.propertyHandoverDate,
                      fixedTermAgreementMaxLengthInMonths,
                    ),
                  ],
                }"
              >
                <LazyRDatepicker
                  v-model="detailsData.endDate"
                  name="propertyEndDate"
                  :disabled="!detailsData.propertyHandoverDate"
                  :disabled-date="disabledDatepickerDatesForFixedPL"
                  :error-text="$t('form.validation.pick_date')"
                  :label="$t('agreement.label.end_date')"
                  required
                  :validation-failed="
                    (meta?.validated && !meta?.valid && meta?.touched) ||
                    (meta?.validated && !meta?.valid && !!detailsData.endDate) ||
                    (meta?.validated && !meta?.valid && validationExtraAttention)
                  "
                  :validation-passed="meta?.validated && meta?.valid"
                  @input="
                    validateAndPutAgreement(
                      { endDate: detailsData.endDate },
                      'endDateRef',
                    );
                    meta.touched = true;
                  "
                />
              </Field>
            </div>

            <r-button
              class="mt-9"
              color="gray"
              no-background
              no-padding
              @click="openAgreementEndDateInfo()"
            >
              <r-icon class="text-2xl" icon-name="circle-question" />
            </r-button>
          </div>
        </div>

        <!--   Rendin general notes -->
        <div
          class="my-4 p-4 rounded-lg bg-peach-100 flex flex-col sm:flex-row justify-between sm:items-center gap-4"
        >
          <div>
            <div class="text-gray-900 font-medium">
              {{ $t('agreement.label.general_terms_conditions') }}
            </div>
            <div class="text-sm text-gray-600">
              {{ $t('agreement.disclaimer.general_terms_conditions') }}
            </div>
          </div>
          <div class="flex-shrink-0">
            <r-button
              border-color="light-gray"
              class="w-full sm:w-auto"
              color="black"
              inverted
              :label="$t('read_more')"
              size="small"
              @click="openTermsAndConditions()"
            />
          </div>
        </div>

        <!--    Agreement special notes -->
        <r-textarea
          v-model="detailsData.specialNotes"
          :label="$t('agreement.label.specialNotes')"
          name="agreementStartDate"
          @blur="validateAndPutAgreement({ specialNotes: detailsData.specialNotes })"
        />
        <div class="text-sm text-gray-600 my-2">
          {{ $t('agreement.disclaimer.specialNotes') }}
        </div>
      </div>

      <!--   2. Monthly rent fee  -->
      <div class="bg-white rounded-lg shadow-lg p-4 md:px-10 my-2">
        <div class="text-rendin-700 uppercase font-medium my-4">
          {{ $t('agreement.label.monthly_rent_fee') }}
        </div>
        <div class="text-gray-600 my-2">
          {{ $t('agreement.disclaimer.monthly_rent_fee') }}
        </div>

        <div class="grid sm:grid-cols-2 gap-4 my-4">
          <Field
            ref="rentAmountRef"
            v-slot="{ meta }"
            v-model="rentAmountModel"
            :rules="{ required: true, min_value: 0.1, isFloat: true }"
            name="agreementPaymentAmount"
          >
            <r-input
              v-model="rentAmountModel"
              :error-text="$t('form.validation.monthly_rent')"
              field-type="tel"
              inputmode="decimal"
              :label="
                $t('agreement.label.monthly_rent') + ' (' + detailsData.currency + ')'
              "
              name="agreementPaymentAmount"
              required
              :validation-failed="
                (meta?.validated && !meta?.valid && meta?.touched) ||
                (meta?.validated && !meta?.valid && !!rentAmountModel) ||
                (meta?.validated && !meta?.valid && validationExtraAttention)
              "
              :validation-passed="meta?.validated && meta?.valid"
              @blur="
                validateAndPutAgreement(
                  { rentAmount: detailsData.rentAmount },
                  'rentAmountRef',
                );
                meta.touched = true;
              "
            />
          </Field>

          <Field
            ref="paymentDayRef"
            v-slot="{ meta }"
            v-model="detailsData.paymentDay"
            name="agreementPaymentDay"
            rules="required|between:1,31|numeric"
          >
            <r-input
              v-model="detailsData.paymentDay"
              :error-text="$t('form.validation.monthly_rent_payment_day')"
              field-type="tel"
              :label="$t('agreement.label.monthly_rent_payment_day')"
              name="agreementPaymentDay"
              required
              :validation-failed="
                (meta?.validated && !meta?.valid && meta?.touched) ||
                (meta?.validated && !meta?.valid && !!detailsData.paymentDay) ||
                (meta?.validated && !meta?.valid && validationExtraAttention)
              "
              :validation-passed="meta?.validated && meta?.valid"
              @blur="
                validateAndPutAgreement(
                  { paymentDay: detailsData.paymentDay },
                  'paymentDayRef',
                );
                meta.touched = true;
              "
            />
          </Field>
        </div>
      </div>

      <!--   3. Other fees  -->
      <div class="bg-white rounded-lg shadow-lg p-4 md:px-10 my-2">
        <div class="text-rendin-700 uppercase font-medium my-4">
          {{ $t('agreement.label.other_fees') }}
        </div>
        <div class="text-gray-600 my-2">
          {{ $t('agreement.disclaimer.other_fees') }}
        </div>
        <!--  administration fees card (PL only)-->
        <div
          v-if="
            agreementFromFirebase.agreementTypeFromAgreement === agreementTypes.PL_NO ||
            agreementFromFirebase.agreementTypeFromAgreement === agreementTypes.PL_NI ||
            agreementFromFirebase.agreementTypeFromAgreement === agreementTypes.PL_RE
          "
          class="my-4 p-4 rounded-lg bg-peach-100 flex flex-col sm:flex-row justify-between gap-4"
        >
          <div>
            <div class="text-gray-900 font-medium">
              {{ $t('agreement.label.administrative_rent') }}
            </div>
            <div class="text-sm text-gray-600">
              {{ $t('agreement.disclaimer.administrative_rent') }}
            </div>
            <div v-if="administrativeRentFeesList.length > 0">
              <div
                v-for="(fee, index) in administrativeRentFeesList"
                :id="fee.id"
                :key="index"
                class="flex flex-row flex-wrap gap-2 mt-4"
              >
                <div
                  v-if="fee.paymentDetermination"
                  class="flex flex-nowrap justify-between items-center text-sm bg-rendin-100 rounded-lg"
                >
                  <div
                    v-if="
                      fee.paymentDetermination ===
                      administrativeFeePaymentDetermination.FIXED
                    "
                    class="py-1 px-3"
                  >
                    {{ $t('administrative_rent.amount') }}:
                    <span v-if="fee.paymentAmount" class="whitespace-nowrap">
                      {{ localizedCurrencyFormat(fee.paymentAmount, getLocale) }}
                      {{ detailsData.currency }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="fee.paymentIncludedInRent"
                  class="flex flex-nowrap justify-between items-center text-sm bg-rendin-100 rounded-lg"
                >
                  <span class="py-1 px-3">
                    {{ $t('administrative_rent.included') }}
                  </span>
                </div>
                <div
                  v-if="fee.paymentFrequency"
                  class="flex flex-nowrap justify-between items-center text-sm bg-rendin-100 rounded-lg"
                >
                  <span class="py-1 px-3">
                    {{ $t('agreement.label.monthly_rent_payment_day') }}
                    {{ fee.paymentFrequency }}
                  </span>
                </div>
                <div
                  v-if="fee.paymentRecipient"
                  class="flex flex-nowrap justify-between items-center text-sm bg-rendin-100 rounded-lg"
                >
                  <span
                    v-if="
                      fee.paymentRecipient ===
                      administrativeFeePaymentPartyType.LANDLORD
                    "
                    class="py-1 px-3"
                  >
                    {{ $t('administrative_rent.landlord_receives') }}
                  </span>
                  <span
                    v-else-if="
                      fee.paymentRecipient ===
                      administrativeFeePaymentPartyType.SERVICE_PROVIDER
                    "
                    class="py-1 px-3"
                  >
                    {{ $t('administrative_rent.management_receives') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-shrink-0">
            <r-button
              border-color="light-gray"
              class="w-full sm:w-auto"
              color="black"
              inverted
              :label="
                administrativeRentFeesList.length === 0
                  ? $t('buttons.add')
                  : $t('buttons.manage')
              "
              size="small"
              @click="openAdministrativeRent()"
            />
          </div>
        </div>

        <!--    utilities and services -->
        <div
          class="my-4 p-4 rounded-lg bg-peach-100 flex flex-col sm:flex-row justify-between sm:items-center gap-4"
          data-testid="component.agreement-details.services-and-utilities"
        >
          <div class="flex flex-col w-full">
            <div class="flex flex-rows justify-between gap-4">
              <div>
                <div class="text-gray-900 font-medium">
                  {{ $t('agreement.label.utilities_services.' + getCountry) }}
                </div>
                <div class="text-sm text-gray-600">
                  {{ $t('agreement.disclaimer.utilities_services.' + getCountry) }}
                </div>
              </div>
              <div class="flex-shrink-0 hidden sm:block">
                <r-button
                  border-color="light-gray"
                  class="w-full sm:w-auto"
                  color="black"
                  data-testid="button.agreement-details.open-modal"
                  inverted
                  :label="
                    agreementServicesAndUtilitiesFromFirebase?.length > 0
                      ? $t('buttons.manage')
                      : $t('buttons.add')
                  "
                  size="small"
                  @click="openAddUtilitiesAndServices()"
                />
              </div>
            </div>
            <div
              v-if="tenantSortedServicesList.length > 0"
              class="mt-4"
              data-testid="component.agreement-details.tenant-list"
            >
              <span class="text-sm font-medium text-gray-600">
                {{ $t('administrative_rent.paid_by_tenant') }}
              </span>
              <div class="flex flex-row flex-wrap gap-2 mt-2">
                <div
                  v-for="service in tenantSortedServicesList"
                  :key="service.key"
                  class="bg-rendin-100 rounded-lg flex items-center"
                  :data-testid="'component.agreement-details.' + service.type"
                >
                  <div
                    class="py-1 px-3 text-sm flex flex-wrap gap-1 max-w-min sm:max-w-max"
                  >
                    <span class="min-w-max">
                      {{ servicesAndUtilitiesName(service.type, service.typeName) }}
                      {{
                        service.payment?.amount
                          ? localizedCurrencyFormat(service.payment.amount, getLocale) +
                            ' ' +
                            agreementFromFirebase.currency
                          : ''
                      }}
                    </span>
                    <span v-if="service.payment?.details" class="min-w-max">
                      / {{ $t('administrative_rent.payment_details') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="landlordSortedServicesList.length > 0"
              class="mt-4"
              data-testid="component.agreement-details.landlord-list"
            >
              <span class="text-sm font-medium text-gray-600">
                {{ $t('administrative_rent.paid_by_landlord') }}
              </span>
              <div class="flex flex-row flex-wrap gap-2 mt-2">
                <div
                  v-for="service in landlordSortedServicesList"
                  :key="service.key"
                  class="bg-rendin-100 rounded-lg flex items-center"
                  :data-testid="'component.agreement-details.' + service.type"
                >
                  <div
                    class="py-1 px-3 text-sm flex flex-wrap gap-1 max-w-min sm:max-w-max"
                  >
                    <span class="min-w-max">
                      {{ servicesAndUtilitiesName(service.type, service.typeName) }}
                      {{
                        service.payment?.amount
                          ? localizedCurrencyFormat(service.payment.amount, getLocale) +
                            ' ' +
                            agreementFromFirebase.currency
                          : ''
                      }}
                    </span>
                    <span
                      v-if="service.payment?.details"
                      class="min-w-max"
                      data-testid="component.agreement-details.service-details"
                    >
                      / {{ $t('administrative_rent.payment_details') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-shrink-0 sm:hidden mt-4">
              <r-button
                border-color="light-gray"
                class="w-full sm:w-auto"
                color="black"
                data-testid="button.agreement-details.open-modal"
                inverted
                :label="
                  agreementServicesAndUtilitiesFromFirebase?.length > 0
                    ? $t('buttons.manage')
                    : $t('buttons.add')
                "
                size="small"
                @click="openAddUtilitiesAndServices()"
              />
            </div>
          </div>
        </div>
        <!--   agents & contract fees card -->
        <div
          class="my-4 p-4 rounded-lg bg-peach-100 flex flex-col sm:flex-row justify-between gap-4"
        >
          <div>
            <div class="text-gray-900 font-medium">
              {{ $t('agreement.label.agent_contract_fees') }}
            </div>
            <div class="text-sm text-gray-600">
              {{ $t('agreement.disclaimer.agent_contract_fees') }}
            </div>

            <div v-if="agentAndContractFeesListForTenants.length > 0" class="mt-4">
              <div class="text-sm font-medium text-gray-600">
                {{ $t('administrative_rent.paid_by_tenant') }}
              </div>
              <div class="flex flex-row flex-wrap gap-2 mt-1">
                <div
                  v-for="(fee, index) in agentAndContractFeesListForTenants"
                  :id="fee.id"
                  :key="index"
                  class="bg-rendin-100 rounded-lg"
                >
                  <div class="flex flex-nowrap justify-between items-center">
                    <div class="py-1 px-3 text-sm">
                      <span v-if="fee.type === additionalFeeType.BROKER_FEE">
                        {{ $t('additional_fee.dropdown_types.broker_fee') }}
                      </span>
                      <span v-else-if="fee.type === additionalFeeType.CONTRACT_FEE">
                        {{ $t('additional_fee.dropdown_types.contract_fee') }}
                      </span>
                      <span class="whitespace-nowrap">
                        {{ ' ' }}
                        {{ localizedCurrencyFormat(fee.amount, getLocale) }}
                        {{ detailsData.currency }}
                      </span>
                    </div>
                    <r-button
                      color="black"
                      icon="xmark"
                      icon-class="text-xl mr-3 ml-3 my-1"
                      no-background
                      no-padding
                      @click="deleteAgreementAdditionalFee(fee)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="agentAndContractFeesListForLandlords.length > 0" class="mt-4">
              <div class="text-sm font-medium text-gray-600">
                {{ $t('administrative_rent.paid_by_landlord') }}
              </div>

              <div class="flex flex-row flex-wrap gap-2 mt-1">
                <div
                  v-for="(fee, index) in agentAndContractFeesListForLandlords"
                  :id="fee.id"
                  :key="index"
                  class="bg-rendin-100 rounded-lg"
                >
                  <div class="flex flex-nowrap justify-between items-center">
                    <div class="py-1 px-3 text-sm">
                      <span v-if="fee.type === additionalFeeType.BROKER_FEE">
                        {{ $t('additional_fee.dropdown_types.broker_fee') }}
                      </span>
                      <span v-else-if="fee.type === additionalFeeType.CONTRACT_FEE">
                        {{ $t('additional_fee.dropdown_types.contract_fee') }}
                      </span>
                      <span class="whitespace-nowrap">
                        {{ ' ' }}
                        {{ localizedCurrencyFormat(fee.amount, getLocale) }}
                        {{ detailsData.currency }}
                      </span>
                    </div>
                    <r-button
                      color="black"
                      icon="xmark"
                      icon-class="text-xl mr-3 ml-3 my-1"
                      no-background
                      no-padding
                      @click="deleteAgreementAdditionalFee(fee)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-shrink-0">
            <r-button
              v-if="showAddAgentAndContractFees"
              border-color="light-gray"
              class="w-full sm:w-auto"
              color="black"
              inverted
              :label="$t('buttons.add')"
              size="small"
              @click="openAddAdditionalFees()"
            />
          </div>
        </div>
      </div>

      <!--   4. Payment details  -->
      <div class="bg-white rounded-lg shadow-lg p-4 md:px-10 my-2">
        <div class="text-rendin-700 uppercase font-medium my-4">
          {{ $t('agreement.label.payment_details') }}
        </div>
        <div class="text-gray-600">
          {{ $t('agreement.disclaimer.payment_details') }}
        </div>

        <!--    Agreement bank account -->
        <div class="grid sm:grid-cols-2 gap-4 my-4">
          <Field
            ref="bankAccountNumberRef"
            v-slot="{ meta }"
            v-model="detailsData.bankAccountNumber"
            rules="required|min:14"
            name="agreementBankAccount"
          >
            <r-input
              v-model="detailsData.bankAccountNumber"
              :error-text="$t('form.validation.bank_account_number')"
              :label="$t('agreement.label.bank_account_number')"
              name="agreementBankAccount"
              required
              :validation-failed="
                (meta?.validated && !meta?.valid && meta?.touched) ||
                (meta?.validated && !meta?.valid && !!detailsData.bankAccountNumber) ||
                (meta?.validated && !meta?.valid && validationExtraAttention)
              "
              :validation-passed="meta?.validated && meta?.valid"
              @blur="
                validateAndPutAgreement(
                  { bankAccountNumber: detailsData.bankAccountNumber },
                  'bankAccountNumberRef',
                );
                meta.touched = true;
              "
            />
          </Field>

          <Field
            ref="bankAccountOwnerNameRef"
            v-slot="{ meta }"
            v-model="detailsData.bankAccountOwnerName"
            rules="required"
            name="agreementBankAccountOwner"
          >
            <r-input
              v-model="detailsData.bankAccountOwnerName"
              :error-text="$t('form.validation.bank_account_owner_name')"
              :label="$t('agreement.label.bank_account_owner_name')"
              name="agreementBankAccountOwner"
              required
              :validation-failed="
                (meta?.validated && !meta?.valid && meta?.touched) ||
                (meta?.validated &&
                  !meta?.valid &&
                  !!detailsData.bankAccountOwnerName) ||
                (meta?.validated && !meta?.valid && validationExtraAttention)
              "
              :validation-passed="meta?.validated && meta?.valid"
              @blur="
                validateAndPutAgreement(
                  { bankAccountNumber: detailsData.bankAccountNumber },
                  'bankAccountNumberRef',
                );
                meta.touched = true;
              "
            />
          </Field>
        </div>
      </div>

      <!--   5. Rendin fee  -->
      <div class="bg-white rounded-lg shadow-lg p-4 md:px-10 my-2">
        <div class="text-rendin-700 uppercase font-medium my-4">
          {{ $t('agreement.label.rendin_fee') }}
        </div>
        <div class="text-gray-600">
          {{ $t('agreement.disclaimer.rendin_fee') }}
        </div>

        <!--    Agreement insurance -->
        <div class="grid sm:grid-cols-2 gap-4 my-4">
          <Field
            v-slot="{ meta }"
            v-model="detailsData.insuranceAmount"
            rules="required"
            name="agreementInsuranceAmount"
          >
            <r-input
              v-model="detailsData.insuranceAmount"
              disabled
              field-type="tel"
              :label="
                $t('agreement.label.rendin_service_fee') +
                ' (' +
                detailsData.currency +
                ')'
              "
              name="agreementInsuranceAmount"
              required
              :validation-failed="
                (meta?.validated && !meta?.valid && meta?.touched) ||
                (meta?.validated && !meta?.valid && !!detailsData.insuranceAmount) ||
                (meta?.validated && !meta?.valid && validationExtraAttention)
              "
              :validation-passed="meta?.validated && meta?.valid"
            />
          </Field>

          <Field
            v-slot="{ meta }"
            v-model="detailsData.insurancePaymentDay"
            rules="required|between:1,31"
            name="agreementInsurancePaymentDay"
          >
            <r-input
              disabled
              field-type="tel"
              :label="$t('agreement.label.rendin_service_fee_payment_day')"
              name="agreementInsurancePaymentDay"
              required
              :validation-failed="
                (meta?.validated && !meta?.valid && meta?.touched) ||
                (meta?.validated &&
                  !meta?.valid &&
                  !!detailsData.insurancePaymentDay) ||
                (meta?.validated && !meta?.valid && validationExtraAttention)
              "
              :validation-passed="meta?.validated && meta?.valid"
              :model-value="detailsData.insurancePaymentDay"
            />
          </Field>
        </div>

        <!--    Agreement insuranceInvoiceTo-->
        <div class="my-4">
          <r-select
            v-model="insuranceInvoiceSelect"
            :label="$t('agreement.label.insurance_invoice_select')"
            name="insurance"
            :no-options-text="$t('form.validation.select_no_options')"
            option-label="text"
            :options="insuranceInvoiceOptions"
            placeholder="selectPlaceholderData"
            @input="
              validateAndPutAgreement({
                insuranceInvoiceTo: insuranceInvoiceSelect.value,
              })
            "
          />
        </div>
      </div>
      <!--      </form>-->
    </Form>

    <r-overlay size="large" :visible="showOverlay" @close="closeOverlay()">
      <div class="px-4 sm:px-12 pt-10 pb-4">
        <div v-if="showTermsAndConditions">
          <LazyRendinGeneralTermsModal
            :agreement-from-firebase="agreementFromFirebase"
          />
        </div>
      </div>
    </r-overlay>

    <!--    Agreement type select modal, PL-->
    <LazyAgreementTypeSelectModal
      :agreement-from-firebase="agreementFromFirebase"
      :agreement-id="agreementId"
      :show-modal="showAgreementTypeModalPl"
      @agreement-type-changed="$emit('agreementTypeChanged')"
      @close-overlay="closeTypeModalPL()"
    />

    <!--    Agreement type modal for EE, deprecated option to update profile for PL-->
    <LazyAgreementTypeModal
      :agreement-from-firebase="agreementFromFirebase"
      :agreement-id="agreementId"
      :show-modal="showAgreementTypeModal"
      @close-overlay="showAgreementTypeModal = false"
      @move-to-tab="(tabName) => moveToTab(tabName)"
      @request-tabs-validation="requestTabsValidation()"
    />
    <!--    Add administrative rent (PL only)-->
    <AddAdministrativeRentModal
      v-if="showAdministrativeFee"
      :agreement-from-firebase="agreementFromFirebase"
      :agreement-id="agreementId"
      :show-modal="showAdministrativeFee"
      @close-overlay="showAdministrativeFee = false"
      @delete-administrative-fee="cleanAdministrativeFee()"
      @save-administrative-fee="saveAdministrativeFee($event)"
    />
    <!--    Add fixed fee modal-->
    <LazyAddAgentAndContractFeesModal
      v-if="showAddAdditionalFees && showAddAgentAndContractFees"
      :agreement-from-firebase="agreementFromFirebase"
      :agreement-id="agreementId"
      :show-modal="showAddAdditionalFees"
      @close-overlay="showAddAdditionalFees = false"
    />
    <!--    Add utilities and services modal-->
    <AgreementUtilitiesAndServicesModal
      v-if="showAddUtilitiesAndServices"
      :agreement-from-firebase="agreementFromFirebase"
      :agreement-id="agreementId"
      :agreement-services-and-utilities-from-firebase="
        agreementServicesAndUtilitiesFromFirebase
      "
      :agreement-workflow="agreementFromFirebase.workflow"
      :currency="agreementFromFirebase.currency"
      :show-modal="showAddUtilitiesAndServices"
      @close-overlay="closeUtilitiesAndServicesModal()"
    />
    <!--    Property handover date  info modal -->
    <LazyPropertyHandoverDateInfoModal
      v-if="showPropertyHandoverDateInfo"
      :show-modal="showPropertyHandoverDateInfo"
      @close-overlay="showPropertyHandoverDateInfo = false"
    />
    <!--    Agreement end date info modal -->
    <LazyAgreementEndDateInfoModal
      v-if="showAgreementEndDateInfo"
      :show-modal="showAgreementEndDateInfo"
      @close-overlay="showAgreementEndDateInfo = false"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation';
import AgreementTypeModal from '~/components/section/agreement-components/AgreementTypeModal';
import AgreementTypeSelectModal from '~/components/section/agreement-components/AgreementTypeSelectModal';
import AddAgentAndContractFeesModal from '~/components/section/agreement-components/AddAgentAndContractFeesModal';
import AddAdministrativeRentModal from '~/components/section/agreement-components/AddAdministrativeRentModal';
import RendinGeneralTermsModal from '~/components/section/agreement-components/RendinGeneralTermsModal';
import PropertyHandoverDateInfoModal from '~/components/section/agreement-components/PropertyHandoverDateInfoModal';
import AgreementEndDateInfoModal from '~/components/section/agreement-components/AgreementEndDateInfoModal';
import {
  languageNames,
  agreementTypes,
  agreementContractTypes,
  workflow,
  additionalFeeType,
  administrativeFeePaymentPartyType,
  administrativeFeePaymentDetermination,
  userRole,
} from '~/utils/constants';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';
import AgreementUtilitiesAndServicesModal from '~/components/section/agreement-components/AgreementUtilitiesAndServicesModal';
import { servicesAndUtilitiesNames } from '~/composables/servicesAndUtilitiesNames';
import { localizedCurrencyFormat } from '~/utils/localizedCurrencyFormat';
import { validate, Field, Form } from 'vee-validate';
import { debounce } from 'lodash';

export default {
  name: 'AgreementEditViewDetails',
  components: {
    AgreementUtilitiesAndServicesModal,
    AgreementTypeModal,
    AgreementTypeSelectModal,
    AddAgentAndContractFeesModal,
    AddAdministrativeRentModal,
    RendinGeneralTermsModal,
    PropertyHandoverDateInfoModal,
    AgreementEndDateInfoModal,
    Form,
    Field,
  },

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementServicesAndUtilitiesFromFirebase: {
      type: Array,
      default: null,
    },
    validate: {
      type: Number,
      default: null,
    },
    validationExtraAttention: {
      type: Boolean,
      default: false,
    },
    forceOpenUtilitiesAndServices: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'requestServerValidation',
    'agreementDetailsFormValidation',
    'requestTabsValidation',
    'moveToTab',
    'utilitiesAndServicesModalClosed',
    'agreementTypeChanged',
  ],

  setup() {
    return {
      rendinFeeCalculation,
      localizedCurrencyFormat,
    };
  },

  data: (vm) => ({
    id: null,
    agreementLanguageSelect: {
      text: '',
      value: '',
      language: '',
    },
    agreementLanguageOptionsEE: [
      {
        text: languageNames.EE,
        value: 'ee',
        language: 'et-EE',
      },
      {
        text: languageNames.EN,
        value: 'en',
        language: 'en-GB',
      },
      {
        text: languageNames.RU,
        value: 'ru',
        language: 'ru-RU',
      },
    ],
    agreementLanguageOptionsPL: [
      {
        text: languageNames.PL,
        value: 'pl',
        language: 'pl-PL',
      },
    ],
    insuranceInvoiceSelect: {
      text: vm.$t('tenant'),
      value: 'TENANT',
    },
    insuranceInvoiceOptions: [
      {
        text: vm.$t('tenant'),
        value: 'TENANT',
      },
      {
        text: vm.$t('landlord'),
        value: 'LANDLORD',
      },
    ],
    administrativeFeePaymentPartyType: administrativeFeePaymentPartyType,
    administrativeFeePaymentDetermination: administrativeFeePaymentDetermination,
    detailsData: {
      agreementType: null,
      contractType: null,
      language: null,
      propertyHandoverDate: null,
      endDate: null,
      specialNotes: null,
      rentAmount: null,
      currency: 'EUR',
      paymentDay: null,
      additionalFees: [],
      bankAccountNumber: null,
      bankAccountOwnerName: null,
      insuranceAmount: null,
      insurancePaymentDay: 15,
      insuranceInvoiceTo: 'TENANT',
      administrativeFees: [],
    },
    showOverlay: false,
    showAgreementTypeModal: false,
    showAgreementTypeModalPl: false,
    showAddUtilitiesAndServices: false,
    showAdministrativeFee: false,
    showAddAdditionalFees: false,
    showTermsAndConditions: false,
    showPropertyHandoverDateInfo: false,
    showAgreementEndDateInfo: false,
    fixedTermAgreementMinLengthInMonths: 3,
    fixedTermAgreementMaxLengthInMonths: 120,
    languageNames: languageNames,
    agreementTypes: agreementTypes,
    agreementContractTypes,
    workflow,
    additionalFeeType,
    trackingProcessNames: trackingProcessNames,
    agreementTrackingEventNames: agreementTrackingEventNames,

    actions: {
      putAgreementDebounced: () => undefined,
    },
  }),

  computed: {
    ...mapGetters({
      getCountry: 'getCountry',
      getLocale: 'getLocale',
      getAgreementInProgress: 'agreements/getAgreementInProgress',
      postAgreementAdditionalFeeInProgress:
        'agreements/postAgreementAdditionalFeeInProgress',
      deleteAgreementAdditionalFeeInProgress:
        'agreements/deleteAgreementAdditionalFeeInProgress',
    }),

    rentAmountModel: {
      get() {
        return this.detailsData.rentAmount;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.detailsData.rentAmount = normalized;
      },
    },

    tenantSortedServicesList() {
      const tenantList = [];
      if (this.agreementServicesAndUtilitiesFromFirebase?.length > 0) {
        for (const service of this.agreementServicesAndUtilitiesFromFirebase) {
          if (service.whoIsPaying === userRole.TENANT) {
            tenantList.push(service);
          }
        }
      }
      return tenantList.sort((a, b) =>
        this.servicesAndUtilitiesName(a.type, a.typeName).localeCompare(
          this.servicesAndUtilitiesName(b.type, b.typeName),
        ),
      );
    },

    landlordSortedServicesList() {
      const landlordList = [];
      if (this.agreementServicesAndUtilitiesFromFirebase?.length > 0) {
        for (const service of this.agreementServicesAndUtilitiesFromFirebase) {
          if (service.whoIsPaying === userRole.LANDLORD) {
            landlordList.push(service);
          }
        }
      }
      return landlordList.sort((a, b) =>
        this.servicesAndUtilitiesName(a.type, a.typeName).localeCompare(
          this.servicesAndUtilitiesName(b.type, b.typeName),
        ),
      );
    },

    constructDetailsTabError() {
      let dates = '';
      let payments = '';

      if (this.agreementId) {
        if (!this.detailsData.propertyHandoverDate) {
          dates = `<div>${this.$t('agreement.error.dates_missing')}</div>`;
        } else if (
          new Date(this.detailsData.propertyHandoverDate).setHours(0, 0, 0, 0) <
          new Date().setHours(0, 0, 0, 0)
        ) {
          dates = `<div>${this.$t('agreement.error.start_date_in_the_past')}</div>`;
        } else if (
          !this.detailsData.rentAmount ||
          !this.detailsData.paymentDay ||
          !this.detailsData.bankAccountNumber ||
          !this.detailsData.bankAccountOwnerName
        ) {
          payments = `<div>${this.$t('agreement.error.payment_information_missing')}</div>`;
        } else if (
          this.detailsData.propertyHandoverDate &&
          this.detailsData.rentAmount &&
          this.detailsData.paymentDay &&
          this.detailsData.bankAccountNumber &&
          this.detailsData.bankAccountOwnerName
        ) {
          payments = `<div  class="text-gray-900">${this.$t('agreement.error.agreement_info_filled')}</div>`;
        }
        return dates + payments;
      }

      return `<div>${this.$t('agreement.error.add_agreement_details')}</div>`;
    },

    administrativeRentFeesList() {
      const administrativeFees = this.detailsData.administrativeFees.filter(
        (fee) => fee.type === additionalFeeType.ADMINISTRATIVE_FEE,
      );
      if (administrativeFees.length > 0) {
        return [administrativeFees[0]];
      } else {
        return [];
      }
    },

    agentAndContractFeesList() {
      return this.detailsData.additionalFees.filter(
        (fee) =>
          fee.type === additionalFeeType.BROKER_FEE ||
          fee.type === additionalFeeType.CONTRACT_FEE,
      );
    },

    agentAndContractFeesListForTenants() {
      // fallback condition for legacy data structures without payerRole > fee.payerRole !== 'LANDLORD'
      return this.detailsData.additionalFees.filter(
        (fee) =>
          fee.payerRole !== 'LANDLORD' &&
          (fee.type === additionalFeeType.BROKER_FEE ||
            fee.type === additionalFeeType.CONTRACT_FEE),
      );
    },

    agentAndContractFeesListForLandlords() {
      return this.detailsData.additionalFees.filter(
        (fee) =>
          fee.payerRole === 'LANDLORD' &&
          (fee.type === additionalFeeType.BROKER_FEE ||
            fee.type === additionalFeeType.CONTRACT_FEE),
      );
    },

    showAddAgentAndContractFees() {
      const fees = this.agentAndContractFeesList;

      return !(
        fees.some((fee) => fee.type === additionalFeeType.BROKER_FEE) &&
        fees.some((fee) => fee.type === additionalFeeType.CONTRACT_FEE)
      );
    },
  },

  watch: {
    detailsData: {
      deep: true,
      handler(val) {
        if (val.rentAmount > 0) {
          this.detailsData.insuranceAmount = this.rendinFeeCalculation(
            this.detailsData.rentAmount,
            this.getCountry === 'pl' ? 'PL' : 'EE',
          ).toLocaleString(this.getLocale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      },
    },

    agreementFromFirebase: {
      deep: true,
      handler() {
        this.fillEditModeData();
      },
    },

    agreementLanguageSelect(val) {
      if (val && val.value) {
        this.detailsData.language = val.value;
      } else {
        this.detailsData.language = this.getCountry === 'pl' ? 'pl-PL' : 'et-EE';
      }
    },

    insuranceInvoiceSelect(val) {
      if (val) {
        this.detailsData.insuranceInvoiceTo = val.value;
      } else {
        this.detailsData.insuranceInvoiceTo = null;
      }
    },

    validate(val) {
      if (val) {
        this.fillEditModeData();
        this.validateAgreementDetailsForm();
      }
    },
    forceOpenUtilitiesAndServices(val) {
      if (val) {
        this.showAddUtilitiesAndServices = true;
      }
    },
  },

  mounted() {
    this.fillEditModeData();
    this.validateAgreementDetailsForm();

    this.actions.putAgreementDebounced = debounce((data, ref) => {
      if (ref) {
        this.$refs[ref].validate().then((validationResponse) => {
          if (validationResponse.valid && this.agreementId) {
            this.actionPutAgreement({
              id: this.agreementId,
              agreement: { ...data },
            }).then(() => {
              if (this.validationExtraAttention) this.$emit('requestServerValidation');
            });
            this.emitAgreementTrackerEvent(
              this.dataInputEventName(data),
              this.dataCustomProperty(data),
            );
          }
        });
      } else {
        this.actionPutAgreement({ id: this.agreementId, agreement: { ...data } }).then(
          () => {
            if (this.validationExtraAttention) this.$emit('requestServerValidation');
          },
        );
        this.emitAgreementTrackerEvent(
          this.dataInputEventName(data),
          this.dataCustomProperty(data),
        );
      }
    }, 500);
  },

  methods: {
    ...mapActions({
      actionGetAgreement: 'agreements/getAgreement',
      actionPutAgreement: 'agreements/putAgreement',
      reportErrorToSentry: 'tracker/reportErrorToSentry',
      actionGetAgreementServicesAndUtilities:
        'agreements/getAgreementServicesAndUtilities',
      actionDeleteAgreementAdditionalFee: 'agreements/deleteAgreementAdditionalFee',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
      actionDeleteAgreementServicesAndUtilities:
        'agreements/deleteAgreementServicesAndUtilities',
    }),

    fillEditModeData() {
      if (this.agreementFromFirebase) {
        this.actionGetAgreementServicesAndUtilities({ agreementId: this.agreementId });
        this.agreementLanguageSelect = {
          value: this.agreementFromFirebase.language
            ? this.languageValue(this.agreementFromFirebase.language)
            : this.getCountry === 'pl'
              ? 'pl'
              : 'ee',
          language: this.agreementFromFirebase.language
            ? this.agreementFromFirebase.language
            : this.getCountry === 'pl'
              ? 'pl-PL'
              : 'et-EE',
          text: this.agreementFromFirebase.language
            ? this.languageTitle(this.agreementFromFirebase.language)
            : this.getCountry === 'pl'
              ? this.languageNames.PL
              : this.languageNames.EE,
        };
        this.detailsData.contractType = this.agreementFromFirebase.contractType
          ? this.agreementFromFirebase.contractType
          : this.getCountry === 'pl'
            ? 'FIXED'
            : 'TERMLESS';
        this.detailsData.agreementType = this.agreementFromFirebase
          .agreementTypeFromAgreement
          ? this.agreementFromFirebase.agreementTypeFromAgreement
          : this.agreementFromFirebase.agreementTypeFromResolver
            ? this.agreementFromFirebase.agreementTypeFromResolver
            : 'UNDEFINED';
        this.detailsData.language = this.agreementFromFirebase.language
          ? this.agreementFromFirebase.language
          : this.getCountry === 'pl'
            ? 'pl-PL'
            : 'et-EE';
        this.detailsData.propertyHandoverDate = this.agreementFromFirebase.startDate
          ? this.agreementFromFirebase.startDate
          : '';
        this.detailsData.endDate = this.agreementFromFirebase.endDate
          ? this.agreementFromFirebase.endDate
          : '';
        this.detailsData.specialNotes = this.agreementFromFirebase.specialNotes
          ? this.agreementFromFirebase.specialNotes
          : '';
        this.detailsData.rentAmount = this.agreementFromFirebase.rentAmount
          ? this.agreementFromFirebase.rentAmount
          : '';
        this.detailsData.currency = this.agreementFromFirebase.currency
          ? this.agreementFromFirebase.currency
          : '';
        this.detailsData.paymentDay = this.agreementFromFirebase.paymentDay
          ? this.agreementFromFirebase.paymentDay
          : '';
        this.detailsData.additionalFees = this.agreementFromFirebase.additionalFees
          ? this.agreementFromFirebase.additionalFees
          : '';
        this.detailsData.bankAccountNumber = this.agreementFromFirebase
          .bankAccountNumber
          ? this.agreementFromFirebase.bankAccountNumber
          : '';
        this.detailsData.bankAccountOwnerName = this.agreementFromFirebase
          .bankAccountOwnerName
          ? this.agreementFromFirebase.bankAccountOwnerName
          : '';
        this.detailsData.insuranceAmount = this.agreementFromFirebase.insuranceAmount
          ? this.agreementFromFirebase.insuranceAmount
          : '';
        this.detailsData.insuranceInvoiceTo = this.agreementFromFirebase
          .insuranceInvoiceTo
          ? this.agreementFromFirebase.insuranceInvoiceTo
          : '';
        this.insuranceInvoiceSelect = {
          value: this.agreementFromFirebase.insuranceInvoiceTo
            ? this.agreementFromFirebase.insuranceInvoiceTo
            : 'TENANT',
          text:
            this.agreementFromFirebase.insuranceInvoiceTo &&
            this.agreementFromFirebase.insuranceInvoiceTo === 'LANDLORD'
              ? this.$t('landlord')
              : this.$t('tenant'),
        };
        this.detailsData.administrativeFees = this.agreementFromFirebase
          .administrativeFees
          ? this.agreementFromFirebase.administrativeFees
          : [];
      }
    },

    validateForm() {
      return this.$refs.detailsDataForm.validate().then((response) => {
        const result = {
          success: response?.valid,
          error: this.constructDetailsTabError,
        };

        this.$emit('agreementDetailsFormValidation', result);

        return result;
      });
    },

    validateAgreementDetailsForm() {
      this.$nextTick(() => {
        this.validateForm();
      });
    },

    validateAndPutAgreement(data, ref) {
      if (this.agreementId && this.agreementFromFirebase?.status === 'DRAFT')
        this.actions.putAgreementDebounced(data, ref);

      this.validateAgreementDetailsForm();
    },

    updateAgreementInStore() {
      this.actionGetAgreement({ id: this.agreementId }).then(() =>
        this.fillEditModeData(),
      );
    },

    openAgreementTypeModalEE() {
      this.showAgreementTypeModal = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_AGREEMENT_TYPE,
      );
    },

    openAgreementTypeModalPL() {
      this.showAgreementTypeModalPl = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_AGREEMENT_TYPE_CHOICE,
        { location: 'Agreement Tab' },
      );
    },

    closeTypeModalPL() {
      this.showAgreementTypeModalPl = false;
      this.$emit('requestTabsValidation');
    },

    openAddUtilitiesAndServices() {
      this.showAddUtilitiesAndServices = true;
      this.emitAgreementTrackerEvent(this.agreementTrackingEventNames.OPEN_UTILITIES);
    },

    openTermsAndConditions() {
      this.showTermsAndConditions = true;
      this.showOverlay = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_GENERAL_TERMS,
      );
    },

    openAdministrativeRent() {
      this.showAdministrativeFee = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_ADMINISTRATIVE_RENT,
      );
    },

    openAddAdditionalFees() {
      this.showAddAdditionalFees = true;
      this.emitAgreementTrackerEvent(this.agreementTrackingEventNames.OPEN_FEES);
    },

    openPropertyHandoverDateInfo() {
      this.showPropertyHandoverDateInfo = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_PROPERTY_HANDOVER_DATE_INFO,
      );
    },

    openAgreementEndDateInfo() {
      this.showAgreementEndDateInfo = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_AGREEMENT_END_DATE_INFO,
      );
    },

    closeOverlay() {
      this.showOverlay = false;
      this.showTermsAndConditions = false;
    },

    languageValue(lang) {
      if (lang === 'et-EE') {
        return 'ee';
      } else if (lang === 'pl-PL') {
        return 'pl';
      } else if (lang === 'en-GB') {
        return 'en';
      } else if (lang === 'ru-RU') {
        return 'ru';
      }
    },

    languageTitle(lang) {
      if (lang === 'et-EE') {
        return languageNames.EE;
      } else if (lang === 'pl-PL') {
        return languageNames.PL;
      } else if (lang === 'en-GB') {
        return languageNames.EN;
      } else if (lang === 'ru-RU') {
        return languageNames.RU;
      }
    },

    deleteAgreementAdditionalFee(additionalFee) {
      this.detailsData.additionalFees = this.detailsData.additionalFees.filter(
        (fee) => fee.id !== additionalFee.id,
      );

      this.actionDeleteAgreementAdditionalFee({
        agreementId: this.agreementId,
        additionalFeeId: additionalFee.id,
      })
        .then(() => {
          const properties = {
            feeType: additionalFee.type,
            feeFrequency: additionalFee.paymentInterval,
            feeAmount: additionalFee.amount,
            feePayerRole: additionalFee.payerRole,
            feeId: additionalFee.id,
          };
          this.emitAgreementTrackerEvent(
            this.agreementTrackingEventNames.REMOVE_FEE,
            properties,
          );
        })
        .then(() => {
          this.updateAgreementInStore();
        });
    },

    // min 3 months max 120 months from start date
    disabledDatepickerDatesForFixedPL(date) {
      const propertyHandoverDate = new Date(this.detailsData.propertyHandoverDate);
      const minDate = new Date(
        propertyHandoverDate.setMonth(
          propertyHandoverDate.getMonth() + this.fixedTermAgreementMinLengthInMonths,
        ),
      ).setDate(propertyHandoverDate.getDate() - 1);
      const maxDate = new Date(
        propertyHandoverDate.setMonth(
          propertyHandoverDate.getMonth() +
            this.fixedTermAgreementMaxLengthInMonths -
            this.fixedTermAgreementMinLengthInMonths,
        ),
      );

      return date <= minDate || date >= maxDate;
    },

    disabledDatepickerDatesBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    disabledDatepickerDatesBeforeStartDate(date) {
      return (
        date < new Date(this.detailsData.propertyHandoverDate).setHours(0, 0, 0, 0)
      );
    },

    calculateDateIsoStringFromMonths(dateIsoString, periodInMonths) {
      if (dateIsoString) {
        const dateObject = new Date(dateIsoString);
        return new Date(
          dateObject.setMonth(dateObject.getMonth() + periodInMonths),
        ).toISOString();
      } else {
        return null;
      }
    },

    moveToTab(tabName) {
      this.$emit('moveToTab', tabName);
    },

    requestTabsValidation() {
      this.$emit('requestTabsValidation');
    },

    dataInputEventName(dataInput) {
      let eventName = '';

      if (dataInput) {
        if (dataInput.language)
          eventName = this.agreementTrackingEventNames.SET_LANGUAGE;
        else if (dataInput.handoverDate)
          eventName = this.agreementTrackingEventNames.SET_HANDOVER_DATE;
        else if (dataInput.endDate)
          eventName = this.agreementTrackingEventNames.SET_END_DATE;
        else if (dataInput.specialNotes)
          eventName = this.agreementTrackingEventNames.SET_SPECIAL_CONDITIONS;
        else if (dataInput.rentAmount)
          eventName = this.agreementTrackingEventNames.SET_PRICE;
        else if (dataInput.paymentDay)
          eventName = this.agreementTrackingEventNames.SET_PAYMENT_DAY;
        else if (dataInput.bankAccountNumber)
          eventName = this.agreementTrackingEventNames.SET_ACCOUNT_NBR;
        else if (dataInput.bankAccountOwnerName)
          eventName = this.agreementTrackingEventNames.SET_ACCOUNT_OWNER;
        else if (dataInput.insuranceInvoiceTo)
          eventName = this.agreementTrackingEventNames.SET_WHO_PAYS_RENDIN;
      }

      return eventName;
    },
    dataCustomProperty(dataInput) {
      let property = '';

      if (dataInput) {
        if (dataInput.language) property = { value: dataInput.language };
        else if (dataInput.startDate) property = { value: dataInput.startDate };
        else if (dataInput.handoverDate) property = { value: dataInput.handoverDate };
        else if (dataInput.endDate) property = { value: dataInput.endDate };
        else if (dataInput.rentAmount) property = { value: dataInput.rentAmount };
        else if (dataInput.paymentDay) property = { value: dataInput.paymentDay };
        else if (dataInput.insuranceInvoiceTo)
          property = { value: dataInput.insuranceInvoiceTo };
      }

      return property;
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },

    closeUtilitiesAndServicesModal() {
      this.showAddUtilitiesAndServices = false;
      this.$emit('utilitiesAndServicesModalClosed');
    },

    servicesAndUtilitiesName(key, name) {
      return servicesAndUtilitiesNames(key, name);
    },

    saveAdministrativeFee(administrativeFee) {
      if (this.detailsData.administrativeFees.length > 0) {
        this.detailsData.administrativeFees[0] = {
          ...administrativeFee,
          type: 'ADMINISTRATIVE_FEE',
        };
      } else {
        this.detailsData.administrativeFees.push({
          ...administrativeFee,
          type: 'ADMINISTRATIVE_FEE',
        });
      }
    },

    cleanAdministrativeFee() {
      this.detailsData.administrativeFees = [];
    },
  },
};
</script>
